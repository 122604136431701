import React from "react";
import Obfuscate from "react-obfuscate";
import styled from "styled-components";
import { Grid, Typography, useMediaQuery, useTheme, Container, Hidden } from "@material-ui/core";

import Elk from "./images/tandem-elk-solid.png";
import Instagram from "./images/footer/instagram-white.svg";

function Footer(props) {
  const theme = useTheme();

  return (
    <FooterContainer maxWidth={false}>
      <StyledFooter container direction="column" alignItems="center" justifyContent="flex-start">
        <ContactBlock
          container
          direction="row"
          alignContent="center"
          justifyContent="space-between"
        >
          <Grid item xs={12} sm={12} md={5}>
            <Typography variant="h2">
              Let’s get <br />
              <GreenText component="span" variant="h2">
                collaborating
              </GreenText>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={7}>
            <Typography variant="body1" paragraph={true}>
              Ready to bring your brand to life? Give us a shout or swing by our office and let's
              collaborate on something awesome.
            </Typography>
          </Grid>
        </ContactBlock>
        <AddressBlock container direction="row" justifyContent="flex-start" alignItems="center">
          <Grid item xs={6} sm={4}>
            <Grid
              container
              direction={useMediaQuery(theme.breakpoints.up("sm")) ? "row" : "column"}
            >
              <Grid item xs={3}>
                <GreenText variant={useMediaQuery(theme.breakpoints.up("sm")) ? "body2" : "h5"}>
                  STUDIO
                </GreenText>
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <address>
                  102, 1822 - 2 ST SW
                  <br />
                  Calgary, AB T2S 1R9
                </address>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5} sm={4}>
            <Grid
              container
              direction={useMediaQuery(theme.breakpoints.up("sm")) ? "row" : "column"}
            >
              <Grid item xs={3}>
                <GreenText variant={useMediaQuery(theme.breakpoints.up("sm")) ? "body2" : "h5"}>
                  CONTACT
                </GreenText>
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <address>
                  <Obfuscate element="a" email="info@tandemmarketing.ca" /> <br />
                  <Obfuscate element="a" tel="403.228.9526" />
                </address>
              </Grid>
            </Grid>
          </Grid>
          <Hidden only="xs">
            <a
              style={{ marginLeft: "auto", marginRight: "5em" }}
              href="https://www.instagram.com/tandem.creative/"
              target="Instagram"
            >
              <img alt="Tandem is on Instagram @tandem.creative" src={Instagram} />
            </a>
          </Hidden>
        </AddressBlock>
      </StyledFooter>
    </FooterContainer>
  );
}

const ContactBlock = styled(Grid)`
  margin: 0;
  padding: 2em;
  ${props => props.theme.breakpoints.up("sm")} {
    padding: 4em;
    /* padding: 2em 0 0 0; */
  }
  h2 {
    font-weight: ${props => props.theme.typography.fontWeightRegular};
  }
  > button {
    background-color: ${props => props.theme.palette.secondary.dark};
    text-transform: uppercase;
    font-weight: ${props => props.theme.typography.fontWeightBold};
    padding: 0.75em 2em;
    border: 2px ${props => props.theme.palette.secondary.dark} none;
    border-radius: 2px;
  }
`;

const GreenText = styled(Typography)`
  color: ${props => props.theme.palette.secondary.dark};
`;

const StyledFooter = styled(Grid)`
  background-image: url(${Elk});
  background-color: #1b1c1e;
  background-position: top right;
  background-size: 56%;
  background-repeat: no-repeat;
  color: ${props => props.theme.palette.common.white};
  margin: 0 auto;

  max-width: 1920px;
  min-height: 430px;
  ${props => props.theme.breakpoints.only("sm")} {
    background-position: top right;
    background-size: 37%;
  }
  ${props => props.theme.breakpoints.only("md")} {
    background-position: top right;
    background-size: 30%;
  }
  ${props => props.theme.breakpoints.only("lg")} {
    background-position: top right;
    background-size: 21%;
  }
  ${props => props.theme.breakpoints.up("xl")} {
    background-position: top left 460px;
    background-size: 16%;
  }
  > button {
    background-color: ${props => props.theme.palette.secondary.dark};
    text-transform: uppercase;
    font-weight: ${props => props.theme.typography.fontWeightBold};
    padding: 0.75em 2em;
    border: 2px ${props => props.theme.palette.secondary.dark} none;
    border-radius: 2px;
  }
  address {
    font-style: initial !important;
    font-size: 0.75em;
    line-height: 150%;
    a {
      color: ${props => props.theme.palette.common.white};
      text-decoration: none;
    }
  }
`;

const FooterContainer = styled(Container)`
  background-color: ${props => props.theme.palette.common.lightBlack};
`;

const AddressBlock = styled(Grid)`
  margin: 0;
  box-sizing: inherit;
  background-color: ${props => props.theme.palette.common.lightBlack};
  padding: 2em 2em 2em 2em;
  border-top-color: white;
  border-top-style: solid;
  border-top-width: 1px;
  ${props => props.theme.breakpoints.up("sm")} {
    padding: 2em 4em 2em 4em;
  }
`;

export default Footer;
