import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Grid, makeStyles } from "@material-ui/core";
import { Helmet } from "react-helmet";
import purify from "dompurify";

function VideoContainer(props) {
  return (
    <Wrapper>
      <div dangerouslySetInnerHTML={{ __html: props.EmbedCode }} />
    </Wrapper>
  );
}

function Video(props) {
  const classes = useStyles();

  const { matches, project, getTimeOut } = props;
  return (
    <Grid
      container
      justifyContent="center"
      alignContent="space-between"
      className={classes.projectContainer}
    >
      <VideoContainer {...project}></VideoContainer>
    </Grid>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    overflow: "hidden",

    [theme.breakpoints.down("md")]: {
      marginBottom: 8,
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "64px",
    },
  },
  fullWidth: {
    width: "100%",

    margin: "0 auto",

    [theme.breakpoints.up("md")]: {
      // margin: 0,
    },
    [theme.breakpoints.up("lg")]: {
      // margin: "4rem 4rem 1rem 4rem",
    },
  },
  projectContainer: {
    // "& :first-of-type": {
    //   paddingTop: 400,
    // },
    "& :last-of-type": {
      // borderBottom: "solid 5px red",
    },
  },
  projectName: {
    marginRight: "1em",
    marginBottom: "1em",
    marginTop: ".25em",

    [theme.breakpoints.up("sm")]: {},
  },
}));

const Wrapper = styled("div")`
  width: 100vw;
  /* max-width: 768px; */
  margin-top: 1rem;
  margin-bottom: 1rem;
`;
Video.propTypes = { matches: PropTypes.bool, project: PropTypes.object };

export default Video;
