import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { InView } from "react-intersection-observer";
import LazyLoad from "react-lazyload";

import { Grid, makeStyles, Grow, Slide, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import SimpleResponsivePicture from "../SimpleResponsivePicture";

function Audio(props) {
  const classes = useStyles();

  const { matches, project, getTimeOut } = props;

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="space-between"
      className={classes.projectContainer}
    >
      <InView>
        {({ inView, ref, entry }) => {
          return (
            <Grow ref={ref} in={inView} timeout={getTimeOut()}>
              <Grid item>
                <LazyLoad
                  height={400}
                  offset={100}
                  placeholder={
                    <Skeleton animation="wave" variant="rect" width={"100%"} height={"75vw"} />
                  }
                >
                  {(project?.ProjectAssets?.data[0]?.attributes || project?.ProjectAssets?.url) && (
                    <SimpleResponsivePicture
                      imageObj={
                        project?.ProjectAssets?.data[0]?.attributes || project?.ProjectAssets?.url
                      }
                      // sx={{ ...imageStyle }}
                      objectFit={"contain"}
                      className={classes.fullWidth}
                    />
                  )}
                  {project?.MediaDescription && (
                    <DescriptionWrapper variant="body1">
                      {project?.MediaDescription}
                    </DescriptionWrapper>
                  )}
                  {project?.AudioAsset?.data?.attributes?.url && (
                    <AudioComponent
                      controls
                      src={project?.AudioAsset?.data?.attributes?.url}
                      preload="metadata"
                    />
                  )}

                  {!project?.AudioAsset?.data?.attributes?.url && (
                    <>
                      <Typography variant="body1" color={"secondary"}>
                        Missing an Audio file.
                      </Typography>
                    </>
                  )}
                </LazyLoad>
              </Grid>
            </Grow>
          );
        }}
      </InView>
    </Grid>
  );
}
const DescriptionWrapper = styled(Typography)`
 padding 0 5rem;
 margin:2rem 0 1rem;
`;
const AudioComponent = styled("audio")`
  z-index: 99;
  width: 100%;
  margin-top: 1rem;
`;
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    overflow: "hidden",

    [theme.breakpoints.down("md")]: {
      marginBottom: 8,
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "64px",
    },
  },
  fullWidth: {
    width: "100%",

    margin: "0 auto",

    [theme.breakpoints.up("md")]: {
      // margin: 0,
    },
    [theme.breakpoints.up("lg")]: {
      // margin: "4rem 4rem 1rem 4rem",
    },
  },
  projectContainer: {
    // "& :first-of-type": {
    //   paddingTop: 400,
    // },
    "& :last-of-type": {
      // borderBottom: "solid 5px red",
    },
  },
}));

Audio.propTypes = { matches: PropTypes.bool, project: PropTypes.object };

export default Audio;
