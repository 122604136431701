import React, { useState } from "react";
import LazyLoad from "react-lazyload";
import styled from "styled-components";
import { InView } from "react-intersection-observer";
import { Grid, Typography, makeStyles, useTheme, Container, Grow, Dialog } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },

  tmImage: {
    maxWidth: "100%",
    height: "calc(27vw)",
    maxHeight: 520,
    [theme.breakpoints.up("sm")]: {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  tagLines: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    marginLeft: "2rem",
    marginRight: "2rem",
    textAlign: "left",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "2rem",
      marginRight: "2rem",
    },
  },
  marginY: {
    margin: 0,
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
    },
  },
  marginB: {
    margin: 0,
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(8),
    },
  },
  marginT: {
    margin: 0,
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(8),
    },
  },
  highlightedText: {
    fontWeight: 800,
  },
}));

export default function Home(props) {
  // const [open, setOpen] = useState(false);
  // const [image, setImage] = useState();
  // const [imageAlt, setImageAlt] = useState();

  const classes = useStyles();
  const theme = useTheme();

  // NOTE Keep this in case we want a modal window on image click
  // const handleOnClick = event => {
  //   setOpen(true);
  //   setImage(event.currentTarget.currentSrc);
  //   setImageAlt(event.currentTarget.alt);
  // };

  // const handleBackdropClick = event => {
  //   setOpen(false);
  // };
  const { HomeData = [{}], matches } = props;
  const triggerOnce = false;
  return (
    <Container maxWidth="xl" disableGutters={matches}>
      <Grid container alignItems="center" justify="space-around" className={classes.marginY}>
        <InView threshold=".5" triggerOnce={triggerOnce}>
          {({ inView, ref, entry }) => {
            return (
              <Grid item xs={12} sm={4}>
                <Grow ref={ref} in={inView} {...{ timeout: 200 }}>
                  <Typography variant="h4" className={classes.tagLines} align="left">
                    We believe in
                    <Typography
                      variant="h4"
                      className={classes.highlightedText}
                      style={{ color: theme.palette.secondary.dark }}
                      component="span"
                    >
                      {" "}
                      expertise{" "}
                    </Typography>
                    & craftsmanship
                  </Typography>
                </Grow>
              </Grid>
            );
          }}
        </InView>

        <InView threshold=".5" triggerOnce={triggerOnce}>
          {({ inView, ref, entry }) => {
            return (
              <Grid item xs={12} sm={8}>
                <LazyLoad>
                  <Grow ref={ref} in={inView} {...{ timeout: 500 }}>
                    <img
                      src={HomeData.content.misc.first.img}
                      alt={HomeData.content.misc.first.title}
                      height="100%"
                      width="100%"
                    />
                  </Grow>
                </LazyLoad>
              </Grid>
            );
          }}
        </InView>
      </Grid>
      <Grid container spacing={2} align="center" alignItems="center" id="firstImages">
        {HomeData.content.one.map((tile, index) => {
          let timeout = 500 * index;
          // timeout += 1000;
          return (
            <Grid item xs={4 * tile.cols} key={index}>
              <InView threshold={0.5} triggerOnce={triggerOnce}>
                {({ inView, ref, entry }) => {
                  return (
                    <Grow ref={ref} in={inView}>
                      <img
                        src={tile.img}
                        alt={tile.title}
                        height="100%"
                        width="100%"
                        className={classes.tmImage}
                        loading="lazy"
                      />
                    </Grow>
                  );
                }}
              </InView>
            </Grid>
          );
        })}
      </Grid>

      <Grid
        container
        // direction={matches ? "row" : "row-reverse"}
        direction="row-reverse"
        alignItems="center"
        justify="space-around"
        className={classes.marginY}
      >
        <Grid item xs={12} sm={4} align="left">
          <InView threshold=".5" triggerOnce={triggerOnce}>
            {({ inView, ref, entry }) => {
              return (
                <LazyLoad>
                  <Grow ref={ref} in={inView} {...{ timeout: 2000 }}>
                    <Typography variant="h4" ml={8} className={classes.tagLines}>
                      We help clients make
                      <Typography
                        variant="h4"
                        style={{ color: theme.palette.secondary.highlight }}
                        component="span"
                        className={classes.highlightedText}
                      >
                        {" "}
                        intelligent{" "}
                      </Typography>
                      marketing investments
                    </Typography>
                  </Grow>
                </LazyLoad>
              );
            }}
          </InView>
        </Grid>

        <Grid item xs={12} sm={8}>
          <InView threshold=".5" triggerOnce={triggerOnce}>
            {({ inView, ref, entry }) => {
              return (
                <LazyLoad>
                  <Grow ref={ref} in={inView} {...{ timeout: 1000 }}>
                    <img
                      src={HomeData.content.misc.second.img}
                      alt={HomeData.content.misc.second.title}
                      height="100%"
                      width="100%"
                    />
                  </Grow>
                </LazyLoad>
              );
            }}
          </InView>
        </Grid>
      </Grid>

      {/*
      
      
      
      
      */}

      <Grid container alignItems="center" justify="space-around" className={classes.marginY}>
        <InView threshold=".5" triggerOnce={triggerOnce}>
          {({ inView, ref, entry }) => {
            return (
              <Grid item xs={12} sm={4}>
                <Grow ref={ref} in={inView} {...{ timeout: 800 }}>
                  <Typography variant="h4" className={classes.tagLines}>
                    We help build great{" "}
                    <Typography
                      variant="h4"
                      component="span"
                      className={classes.highlightedText}
                      style={{ color: theme.palette.secondary.dark }}
                    >
                      brands{" "}
                    </Typography>
                  </Typography>
                </Grow>
              </Grid>
            );
          }}
        </InView>

        <InView threshold=".5" triggerOnce={triggerOnce}>
          {({ inView, ref, entry }) => {
            return (
              <Grid item xs={12} sm={8}>
                <LazyLoad>
                  <Grow ref={ref} in={inView} {...{ timeout: 300 }}>
                    <img
                      src={HomeData.content.misc.third.img}
                      alt={HomeData.content.misc.third.title}
                      height="100%"
                      width="100%"
                    />
                  </Grow>
                </LazyLoad>
              </Grid>
            );
          }}
        </InView>
      </Grid>
      <Grid container spacing={2} align="center" alignItems="center">
        {HomeData.content.two.map((tile, index) => {
          let timeout = 500 * index;
          timeout += 1000;

          return (
            <Grid item xs={4 * tile.cols} key={index}>
              <InView threshold=".5" triggerOnce={triggerOnce}>
                {({ inView, ref, entry }) => {
                  return (
                    <LazyLoad>
                      <Grow ref={ref} in={inView} {...{ timeout: timeout }}>
                        <img
                          src={tile.img}
                          alt={tile.title}
                          height="100%"
                          width="100%"
                          className={classes.tmImage}
                        />
                      </Grow>
                    </LazyLoad>
                  );
                }}
              </InView>
            </Grid>
          );
        })}
      </Grid>
      <Grid
        container
        direction="row-reverse"
        alignItems="center"
        justify="space-around"
        className={classes.marginY}
      >
        <Grid item xs={12} sm={4} align="left">
          <InView threshold=".5" triggerOnce={triggerOnce}>
            {({ inView, ref, entry }) => {
              return (
                <Grow ref={ref} in={inView} {...{ timeout: 800 }}>
                  <Typography variant="h4" ml={8} className={classes.tagLines}>
                    We uncover the most{" "}
                    <Typography
                      variant="h4"
                      className={classes.highlightedText}
                      style={{ color: theme.palette.secondary.highlight }}
                      component="strong"
                    >
                      {" "}
                      effective{" "}
                    </Typography>
                    strategies
                  </Typography>
                </Grow>
              );
            }}
          </InView>
        </Grid>
        <InView threshold=".5" triggerOnce={triggerOnce}>
          {({ inView, ref, entry }) => {
            return (
              <Grid item xs={12} sm={8}>
                <LazyLoad>
                  <Grow ref={ref} in={inView} {...{ timeout: 500 }}>
                    <img
                      src={HomeData.content.misc.fourth.img}
                      alt={HomeData.content.misc.fourth.title}
                      height="100%"
                      width="100%"
                    />
                  </Grow>
                </LazyLoad>
              </Grid>
            );
          }}
        </InView>
      </Grid>
      {/* <Dialog maxWidth="lg" fullWidth={true} open={open} onBackdropClick={handleBackdropClick}>
        <BigImage src={image} alt={imageAlt} onClick={handleBackdropClick} />
      </Dialog> */}
    </Container>
  );
}
const BigImage = styled("img")`
  width: 100%;
  height: auto;
  &:hover {
    cursor: zoom-out;
  }
`;
