import calais from "../images/Services/calais.jpg";
import calais2x from "../images/Services/calais@2x.jpg";
import chandosBook from "../images/Services/chandosBook.jpg";
import chandosBook2x from "../images/Services/chandosBook@2x.jpg";
import kidcoBeer from "../images/Services/kidcoBeer.jpg";
import kidcoBeer2x from "../images/Services/kidcoBeer@2x.jpg";
import zoo from "../images/Services/zoo.jpg";
import zoo2x from "../images/Services/zoo@2x.jpg";

const ServicesData = [
  {
    image: calais,
    image2x: calais2x,
    title: "Calais Villas",
    cols: 1,
  },
  {
    image: chandosBook,
    image2x: chandosBook2x,
    title: "Gatestone Folder",
    cols: 1,
  },
  {
    image: kidcoBeer,
    image2x: kidcoBeer2x,
    title: "Remington Art",
    cols: 1,
  },
  {
    image: zoo,
    image2x: zoo2x,
    title: "Fresnel Website",
    cols: 1,
  },
];

export default ServicesData;
