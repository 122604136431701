import React, { useEffect } from "react";
import styled from "styled-components";
import { Box } from "@material-ui/core";
/** TODO
 * implement options from CMS */
export default function ResponsiveImage({
  imageObj,
  width = "100%",
  height = "auto",
  // maxHeight = "520px",
  objectFit = "cover", // TODO ObjectFitSize should have a more formal source in the CMS???
  objectPosition = "center",
  sx,
  id = "",
  ...props
}) {
  // useEffect(() => {
  //   if (!imageFormats?.xsmall?.url) {
  //     console.log("imageObj", imageObj);
  //   }
  // }, [imageObj]);

  const imageFormats = imageObj?.formats || {};

  // xlarge: 2560,
  // large: 1920,
  // medium: 1280,
  // small: 960,
  // xsmall: 640,
  // thumbnail: 320,

  // const srcset = `${imageFormats?.thumbnail?.url} 320w, ${imageFormats?.xsmall?.url} 640w, ${imageFormats?.small?.url} 960w, ${imageFormats?.medium?.url} 1280w, ${imageFormats?.large?.url} 1920w, ${imageFormats?.xlarge?.url} 2560w`;

  // const srcset2 = imageFormats
  //   .map((image, index) => {
  //     return `${image.url} ${image.width}w`;
  //   })
  //   .join(", ");

  const srcset = Object.keys(imageFormats)
    .map(format => {
      const url = imageFormats[format]?.url;
      const width = imageFormats[format]?.width;
      return `${url} ${width}w`;
    })
    .join(", ");

  return (
    <Wrapper id="simple-image-wrapper">
      <Box
        component="img"
        src={`${imageFormats?.xsmall?.url || imageFormats?.small?.url || imageObj?.url}`}
        srcSet={srcset}
        sizes={`${width}`}
        id={id}
        //TODO setup a styled component to handle breakpoints

        sx={{
          width,
          height,
          //  maxHeight,
          objectFit,
          objectPosition,
          ...sx,
        }}
        // style={{ width, height, objectFit, ...style }}
        alt={imageObj?.alternativeText}
      />
    </Wrapper>
  );
}
const Wrapper = styled(Box)`
  display: flex;
  justify-content: center;
  position: relative;
  max-width: 100%;
  max-height: 100%;
  > #image-overlay {
    font-family: "Montserrat", sans-serif !important;
  }
`;
