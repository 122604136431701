import axios from "axios";
import { getLocalStorage } from "./getLocalStorage";
import { removeCommentAttributes } from "./functions";
const qs = require("qs");
export async function getIdentities(setter, key) {
  //"http://[API_ENDPOINT]:1337/api/identity/?populate[Hero][populate]=%2A&populate[Intro][populate]=%2A"
  const query = qs.stringify(
    {
      populate: "deep",
      // populate: {
      //   Project: {
      //     populate: ["layout_type"],
      //   },
      // },
      // populate: ["Project.layout_type.data.attributes.Name", "Project.LayoutMedia"],
      // populate: {
      //   Project: {
      //     on: {
      //       "basic.layout-picker": {
      //         // populate: "*",
      //         populate: {
      //           fields: ["test1"],
      //           LayoutMedia: {
      //             fields: ["formats", "alternativeText", "caption", "ext", "height"],
      //           },
      //           layout_type: {
      //             fields: ["Name"],
      //           },
      //         },
      //       },
      //     },
      //   },
      // },
    },
    {
      encodeValuesOnly: true,
    }
  );
  const config = {
    headers: {
      Authorization: "Bearer " + process.env.REACT_APP_STRAPI_ACCESS_TOKEN,
    },
  };

  axios
    .get(`${process.env.REACT_APP_CMS_URL}/api/identity/?${query}`, config)
    .then(res => {
      const dataTest = removeCommentAttributes(JSON.stringify(res.data.data.attributes));

      const modifiedData = JSON.parse(
        dataTest.replace(/\/uploads\/+/g, `${process.env.REACT_APP_CMS_URL}/uploads/`)
      );
      setter(modifiedData);

      localStorage.setItem(key, JSON.stringify(modifiedData));
      return modifiedData;
    })
    .catch(function (error) {
      getLocalStorage(setter, key);
      console.log("Error fetching page data from server :: ", error.message);
      console.log("Has your content been published");
    });
}
