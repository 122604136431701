import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Box } from "@material-ui/core";
import playIcon from "./images/PlayButton.png"; // Replace with your play button image
import pauseIcon from "./images/PauseButton.png"; // Replace with your pause button image
import SimpleResponsivePicture from "./SimpleResponsivePicture";

function AudioPlayerWithProgressBar({ mp3File, imageObj }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const audioRef = useRef(new Audio(mp3File));

  useEffect(() => {
    const audioElement = audioRef.current;

    const updateProgress = () => {
      const newProgress = (audioElement.currentTime / audioElement.duration) * 100;
      setProgress(newProgress);
    };

    const handleEnded = () => {
      setIsPlaying(false);
      setProgress(0);
    };

    audioElement.addEventListener("timeupdate", updateProgress);
    audioElement.addEventListener("ended", handleEnded);

    return () => {
      audioElement.removeEventListener("timeupdate", updateProgress);
      audioElement.removeEventListener("ended", handleEnded);
    };
  }, []);

  const togglePlayPause = () => {
    const audioElement = audioRef.current;

    if (isPlaying) {
      audioElement.pause();
    } else {
      audioElement.play();
    }

    setIsPlaying(!isPlaying);
  };

  return (
    <>
      <Wrapper>
        <PlayButton
          onClick={togglePlayPause}
          src={isPlaying ? pauseIcon : playIcon}
          alt={isPlaying ? "Pause" : "Play"}
        />
        <BaseImage src={imageObj.url} />
        <SimpleResponsivePicture
          imageObj={imageObj}
          // sx={{ ...imageStyle }}
          objectFit={"contain"}
          styles={{ width: "100%", margin: "0 auto", position: "absolute" }}
        />
      </Wrapper>

      <progress value={progress} max="100" style={{ width: "100%", height: "2rem" }} />
    </>
  );
}

const Wrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PlayButton = styled("img")`
  width: 10%;
  max-width: 200px;
  height: auto;
  z-index: 999;
  position: absolute;
`;
const BaseImage = styled("img")`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
`;

export default AudioPlayerWithProgressBar;
