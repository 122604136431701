import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Obfuscate from "react-obfuscate";
import {
  Grid,
  Typography,
  makeStyles,
  Container,
  ButtonBase,
  Grow,
  useTheme,
} from "@material-ui/core";

import Circles from "./images/sketchbook/circles.svg";
import Pencils from "./images/sketchbook/pencils.png";
function SketchBook(props) {
  const getTimeOut = props.getTimeOut;

  return (
    <Wrapper disableGutters maxWidth="xl">
      <BookContainer container direction="row" justifyContent="space-evenly" alignItems="center">
        <Book item xs={10} sm={12}>
          <Typography variant="h3">Cool!</Typography>
          <Typography paragraph>
            We can't wait to see what you have been inspired to create!
          </Typography>
          <Typography paragraph>
            Send us a file of your sketch from a photo or scan to{" "}
            <Obfuscate element="a" email="sketches@tandemmarketing.ca" />
          </Typography>
          <Typography paragraph>
            Ideally you will send us a jpeg/jpg file that is no larger than 5MB. We'll be able to
            use virtually any format though. We are that good. Except MS Word files because that
            would be just wrong.
          </Typography>
        </Book>
      </BookContainer>
    </Wrapper>
  );
}

const Wrapper = styled(Container)`
  background-color: #f7f5e6;
`;
const BookContainer = styled(Grid)`
  background: left top 25px/150px url(${Pencils}) no-repeat, bottom/200px url(${Circles}) no-repeat;
  ${props => props.theme.breakpoints.up("sm")} {
    background: left center/20% url(${Pencils}) no-repeat, bottom/300px url(${Circles}) no-repeat;
  }
`;
const Book = styled(Grid)`
  padding: 5rem 1rem;
  min-height: 500px;

  ${props => props.theme.breakpoints.up("sm")} {
    padding: 5rem 10rem;
    max-width: 800px;
  }
`;

SketchBook.propTypes = {};

export default SketchBook;
