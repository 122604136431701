import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { InView } from "react-intersection-observer";
import { Grid, Typography, makeStyles, Container, ButtonBase, Grow } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import Tuscan from "./images/footer/Identities_TuscanRise@2x.jpg";
import Zoo from "./images/footer/Zoo_FlamingoAd@2x.jpg";
import Remington from "./images/footer/Remington_Wood1@2x.jpg";
import TwentyYears from "./images/footer/20Years_1@2x.jpg";
import Fresnel from "./images/footer/Fresnel_Web@2x.jpg";
import Mawer from "./images/footer/Mawer_Mag1@2x.jpg";

function Project(props) {
  const classes = useStyles();
  const handleProjectClick = props.handleProjectClick;
  const getTimeOut = props.getTimeOut;
  let card = "/video/bizcard_tf_sleeve.mp4";
  if (props.card === "tf") {
    card = "/video/bizcard_tf_sleeve.mp4";
  }
  if (props.card === "na") {
    card = "/video/bizcard_na_sleeve.mp4";
  }
  if (props.card === "jp") {
    card = "/video/bizcard_jp_sleeve.mp4";
  }
  if (props.card === "ak") {
    card = "/video/bizcard_ak_sleeve.mp4";
  }
  if (props.card === "ej") {
    card = "/video/bizcard_ej_sleeve.mp4";
  }
  if (props.card === "kh") {
    card = "/video/bizcard_kh_sleeve.mp4";
  }

  return (
    <Container className={classes.root}>
      <Grid container direction="row" justifyContent="space-evenly" alignItems="center">
        <InView>
          {({ inView, ref, entry }) => {
            return (
              <Grow ref={ref} in={inView} timeout={getTimeOut()}>
                <Grid item xs={10} sm={12}>
                  <video controls style={{ width: "100%", maxHeight: "100%" }}>
                    {/* <source src="/media/cc0-videos/flower.webm"
        type="video/webm"> */}
                    <source src={`${card}#t=0.1`} type="video/mp4" />
                    Sorry, your browser doesn't support embedded videos.
                  </video>
                </Grid>
              </Grow>
            );
          }}
        </InView>
      </Grid>
    </Container>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 142,
    marginBottom: "2em",
    [theme.breakpoints.up("sm")]: {
      marginTop: 114,
      marginBottom: "2em",
    },
  },
}));

// TODO Add props
Project.propTypes = {};

export default Project;
