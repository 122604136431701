import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import { ThemeProvider as ThemeProviderSC } from "styled-components";
import smoothscroll from "smoothscroll-polyfill"; //https://github.com/iamdustan/smoothscroll

// Note that the unstable version unstable_createMuiStrictModeTheme is used here to get around the warning generated by strict mode.
// In version 5 of MUI this should be resolved.
import { ThemeProvider, responsiveFontSizes } from "@material-ui/core/styles";
import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core";

const font = "'Source Sans Pro', sans-serif";

smoothscroll.polyfill();
const theme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      secondary: {
        dark: "#BDC93A", //D Green
        main: "#CFDC3D", //L Green
        light: "#d8e363", // a lighter version of the green
        highlight: "#E6781F", //Orange
      },
      common: {
        lightBlack: "#1b1c1e",
        projectBG: "#f4f4f4",
      },
    },
    typography: {
      htmlFontSize: 14,
      fontFamily: font,
      // fontSize: 12,
      h4: { fontWeight: 100 },
      h1: {
        fontWeight: 600,
      },
    },
  })
);

theme.typography.h1 = {
  fontSize: "3.2rem",
  "@media (min-width:600px)": {
    fontSize: "4.5rem",
  },
  "@media (min-width:960px)": {
    fontSize: "6.2125rem",
  },
  "@media (min-width:1280px)": {
    fontSize: "6.8552rem",
  },
};

ReactDOM.render(
  <React.StrictMode>
    <Router basename={"/"}>
      {/* <Router basename={"/"}> */}
      <ThemeProviderSC theme={theme}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </ThemeProviderSC>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
