import React from "react";
import { motion, AnimatePresence, useTransform } from "framer-motion";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Grid, Typography, Container } from "@material-ui/core";

function ProjectHeader(props) {
  const { matches, locationObj, content } = props;

  const TitleAnimation = content?.Hero?.heading?.split("").map((letter, index) => {
    //break at the space since the letters are wrapped in span tags
    if (index === 5 && content.heading === "Brand Development") {
      return <br key={`${letter}${index}`} />;
    }

    return (
      <motion.span
        id={`letter${index}`}
        exit={{ opacity: 0 }}
        key={`${letter}${index}`}
        variants={IndividualVariants}
        style={{ display: "inline-block" }}
      >
        {letter}
      </motion.span>
    );
  });

  // TODO simplify Hero by only passing props
  return (
    <>
      <Hero
        container
        props={props}
        content={content}
        alignContent="center"
        alignItems="center"
        justifyContent="flex-start"
        // headerimage={content.headerImage}
        headerimage={content?.Hero?.HeroImage?.data?.attributes?.url}
        component={MotionDiv}
        path={locationObj.pathname}
        backgroundposition={content?.Hero?.options?.backgroundposition}
        matches={matches}
        backgroundanimation={content?.Hero?.options?.headerVariant}
      >
        {" "}
        <AnimatePresence exitBeforeEnter>
          <motion.div
            key={locationObj.pathname}
            variants={variants}
            exit={{
              scale: 0,
              transition: {
                duration: 0.5,
              },
            }}
          >
            <Typography variant="h1" style={{ whiteSpace: "pre-wrap" }}>
              {TitleAnimation}
            </Typography>

            <motion.div className="body" variants={headerCopy}>
              <Typography variant="body1">{content.description}</Typography>
            </motion.div>
          </motion.div>
        </AnimatePresence>
      </Hero>{" "}
    </>
  );
}
const variants = {
  hidden: { opacity: 0, x: -2000 },
  visible: {
    zIndex: 2,
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      stiffness: 1500,
      damping: 20,
      duration: 0,
      when: "beforeChildren",
      staggerChildren: 0.05,
      staggerDirection: 1,
    },
  },
};
const headerCopy = {
  hidden: { opacity: 0, y: -50 },
  visible: {
    opacity: 0.95,
    y: 0,
    transition: {
      delay: 1.0,
      type: "spring",
      stiffness: 50,
      duration: 0.25,
    },
  },
};
const IndividualVariants = {
  hidden: {
    opacity: 0,
    x: -500,
  },
  visible: {
    opacity: [0, 0.25, 0, 0.1, 0, 0.5, 0.35, 0.95],
    x: 0,
    transition: {
      x: { type: "spring", duration: 0.27 },
      opacity: { duration: 0.5 },
    },
  },
};

/**
 * Component for Hero Grid Component
 *
 * @param {*} props
 * @returns
 */
const MotionDiv = props => {
  const exit = () => {
    if (props.matches) {
      return {
        scaleX: [1, 0, 1],
      };
    }
    return {
      scaleY: [1, 0, 1],
    };
  };

  return (
    <AnimatePresence exitBeforeEnter>
      <AnimatedBackground //This div is for the overlay, animates using a different transition
        key={`${props.path}overlay`}
        initial={{
          zIndex: 0,
          boxShadow: "inset 0 0 0 500px rgba(0, 0, 0, 1)",
        }}
        animate={{
          boxShadow: "inset 0 0 0 500px rgba(0, 0, 0, .75)",
        }}
        exit={exit}
        transition={{ duration: 1, type: "linear" }}
      />

      {!props.matches && (
        <motion.div
          key={props.path}
          {...props}
          initial={props?.content?.Hero?.options?.headerVariant?.initial}
          animate={props?.content?.Hero?.options?.headerVariant?.animate}
          exit={exit}
          transition={
            props?.content?.Hero?.options?.headerVariant?.transition
              ? props?.content?.Hero?.HeroImage?.options?.headerVariant?.transition
              : { type: "linear", duration: 30, yoyo: "Infinity" }
          }
        />
      )}
      {props.matches && <div key={props.path} {...props}></div>}
    </AnimatePresence>
  );
};

const AnimatedBackground = styled(motion.div)`
  position: absolute;
  x: 0;
  y: 0;
  width: 100%;
  height: 90.5vw;
  max-height: 445px;
  ${props => props.theme.breakpoints.only("sm")} {
    height: 51vw;
    max-height: 450px;
  }
`;

const Hero = styled(Grid)`
  margin-top: 93px;
  background: url(${props => props.headerimage})
    ${props => {
      return props.backgroundposition;
    }}
    no-repeat;
  background-origin: border-box;

  max-height: 445px;
  height: 90.5vw;
  ${props => props.theme.breakpoints.only("sm")} {
    max-height: 450px;
  }

  ${props => props.theme.breakpoints.up("sm")} {
    background: url(${props => props.headerimage}) center/cover no-repeat;
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 445px;
    height: 51vw;
  }

  h1 {
    z-index: 500;
    margin: 0 0 0 2rem;
    color: #ffffff;
    line-height: 0.8em;
    text-align: left;
    ${props => props.theme.breakpoints.up("sm")} {
      margin: 0 2rem 0 5%;
    }
  }
  .body {
    margin-top: 1rem;
  }
  p {
    z-index: 500;
    /* margin: 0 0 0 10rem; */
    margin: 0 1rem 0 2rem;
    color: #ffffff;
    text-align: left;
    white-space: pre-wrap;
    width: 80%;
    ${props => props.theme.breakpoints.up("sm")} {
      max-width: 1200px;
      margin: 0 2rem 0 5%;
    }
  }
`;

ProjectHeader.propTypes = {
  content: PropTypes.object,
  locationObj: PropTypes.object,
  matches: PropTypes.bool,
};
ProjectHeader.defaultProps = {
  content: { backgroundposition: "center/cover", heading: "Heading", description: "Description" },
  locationObj: {},
  matches: false,
};

export default ProjectHeader;
