import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Container } from "@material-ui/core";

import One from "./layouts/One";
import Two from "./layouts/Two";
import Four from "./layouts/Four";
import OneTwo from "./layouts/OneTwo";
import TwoOne from "./layouts/TwoOne";
import OneTwoSquare from "./layouts/OneTwoSquare";
import OneOverTwo from "./layouts/OneOverTwo";
import TwoOverOne from "./layouts/TwoOverOne";
import OneThree from "./layouts/OneThree";
import ThreeOne from "./layouts/ThreeOne";

import Video from "./layouts/Video";
import Audio from "./layouts/Audio";
import Embed from "./layouts/Embed";

function ProjectsGeneric({ content, matches, getTimeOut, ...props }) {
  const classes = useStyles();

  return (
    <Container maxWidth="lg" disableGutters className={classes.projects}>
      {content.map((project, index) => {
        let UseComponent = undefined;

        switch (project?.LayoutOptions) {
          case "One":
            UseComponent = One;
            break;
          case "Two":
            UseComponent = Two;
            break;
          // eslint-disable-next-line no-duplicate-case
          case "OneTwo":
            UseComponent = OneTwo;
            break;
          case "TwoOne":
            UseComponent = TwoOne;
            break;
          case "OneTwoSquare":
            UseComponent = OneTwoSquare;
            break;
          case "OneOverTwo":
            UseComponent = OneOverTwo;
            break;

          case "TwoOverOne":
            UseComponent = TwoOverOne;
            break;
          case "OneThree":
            UseComponent = OneThree;
            break;
          case "ThreeOne":
            UseComponent = ThreeOne;
            break;
          case "Four":
            UseComponent = Four;
            break;
          case "Video":
            UseComponent = Video;
            break;
          case "Audio":
            UseComponent = Audio;
            break;
          case "Embed":
            UseComponent = Embed;
            break;
          default:
            UseComponent = One;
        }
        return (
          <Container key={project + index} maxWidth="xl" className={classes.project}>
            <UseComponent getTimeOut={getTimeOut} project={project} key={index} matches={matches} />
          </Container>
        );
      })}
    </Container>
  );
}

// const content = [
//   { image: TheCalais, title: "The Calais" },
//   { image: TheCalais, title: "The Calais" },
//   { image: TheCalais, title: "The Calais" },
// ];

const useStyles = makeStyles(theme => ({
  projects: {
    marginTop: "5em",
  },
  project: { overflow: "hidden", marginTop: "5em" },
  projectName: {
    marginRight: "1em",
    marginBottom: "1em",
    marginTop: ".5em",
    width: "100%",
    maxWidth: 1400,
    [theme.breakpoints.up("sm")]: {},
  },
}));

ProjectsGeneric.propTypes = {
  matches: PropTypes.bool,
  content: PropTypes.array,
};

ProjectsGeneric.defaultProps = {
  matches: false,
  content: [
    {
      images2x: ["/images/default.jpg"],
      images: ["/images/default.jpg"],
      title: "This is a default title",
      LayoutTypes: "One",
    },
  ],
};

export default ProjectsGeneric;
