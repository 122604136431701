import React from "react";
import styled from "styled-components";
import { InView } from "react-intersection-observer";
import LocationMapSVG from "./LocationMap";
import SmallLocationMapSVG from "./LocationMapSmall";
import {
  Grid,
  Typography,
  withStyles,
  Container,
  Hidden,
  Grow,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
function LocationMap(props) {
  const { triggerOnce } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Container maxWidth="xl" disableGutters style={{ marginBottom: ".5em" }}>
      <Grid container justifyContent="center" alignItems="center">
        <InView threshold=".5" triggerOnce={triggerOnce}>
          {({ inView, ref, entry }) => {
            return (
              <Grow ref={ref} in={inView} timeout={{ enter: 300, exit: 200 }}>
                <AddressBlock item xs={12} sm={4} md={3}>
                  <Typography variant="h4">
                    We work & play on <GreenText>17th</GreenText> Avenue
                  </Typography>
                  {matches && (
                    <address>
                      <Typography variant="caption" display="block" style={{ fontStyle: "normal" }}>
                        102, 1822 - 2 ST SW
                      </Typography>
                      <Typography variant="caption" display="block" style={{ fontStyle: "normal" }}>
                        Calgary, AB T2S 1R9
                      </Typography>
                    </address>
                  )}
                </AddressBlock>
              </Grow>
            );
          }}
        </InView>
        <InView>
          {({ inView, ref, entry }) => {
            return (
              <Grow ref={ref} in={inView} timeout={{ enter: 400, exit: 200 }}>
                <Grid item xs={12} sm={10} md={7}>
                  <Hidden smDown>
                    <LocationMapSVG inView={inView} />
                  </Hidden>
                  <Hidden mdUp>
                    <SmallLocationMapSVG style={{ width: "100%" }} inView={inView} />
                  </Hidden>
                </Grid>
              </Grow>
            );
          }}
        </InView>
      </Grid>
    </Container>
  );
}

const AddressBlock = withStyles(theme => ({
  root: { margin: "2rem", position: "relative" },
}))(Grid);

const GreenText = styled("span")`
  color: ${props => props.theme.palette.secondary.dark};
`;

export default LocationMap;
