import React, { useEffect, useState } from "react";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import {
  Typography,
  makeStyles,
  Hidden,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  withStyles,
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 400,
    maxWidth: 1920,
    width: "100%",
    margin: "0 auto",
  },
}));
const TableCell = withStyles(theme => ({
  root: {
    borderBottomColor: theme.palette.secondary.dark,
    borderBottomWidth: 1,
    width: "50%",
    [theme.breakpoints.up("sm")]: {
      width: "25%",
    },
  },
}))(MuiTableCell);

const MotionTableCell = props => {
  return (
    <motion.td variants={props.variants} className={props.className}>
      {props.children}
    </motion.td>
  );
};

const tableVariant = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 0.5, staggerChildren: 0.15 },
  },
  exit: { opacity: 1, transition: { duration: 1 } },
};
const cellVariants = {
  hidden: { opacity: 0, borderBottomColor: "rgba(255,255,255,0)" },
  visible: { opacity: 1, borderBottomColor: "rgba(189,201,58,1)", transition: { duration: 0.3 } },
  exit: { opacity: 0 },
};

export default function BrandServices(props) {
  const classes = useStyles();
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: false, rootMargin: "-100px 0px" });
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
    if (!inView) {
      controls.start("hidden");
    }
  }, [controls, inView]);
  return (
    <>
      <Hidden xsDown>
        <TableContainer>
          <motion.table
            style={{ borderCollapse: "collapse" }}
            initial="hidden"
            variants={tableVariant}
            ref={ref}
            exot="exit"
            animate={controls}
            // component={MotionTable}
            className={classes.table}
            aria-label="Tandem Brand Services"
          >
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="row" colSpan={4}>
                  <Typography variant="h4">Brand Services</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                  Brand Ideation
                </TableCell>
                <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                  Packaging & P.O.P
                </TableCell>
                <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                  Information Design
                </TableCell>
                <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                  Go-to-market Strategies
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                  Logo Development
                </TableCell>
                <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                  Branded Environments
                </TableCell>
                <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                  Internal Communications
                </TableCell>
                <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                  B2B Sales Programs
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                  Print Advertising Campaigns
                </TableCell>
                <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                  Brand Identities
                </TableCell>
                <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                  Naming Conventions
                </TableCell>
                <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                  Collateral & Promotions
                </TableCell>
              </TableRow>
            </TableBody>
          </motion.table>
        </TableContainer>
      </Hidden>

      <Hidden smUp>
        <TableContainer component={Paper}>
          <AnimatePresence exitBeforeEnter>
            <motion.table
              initial="hidden"
              variants={tableVariant}
              ref={ref}
              animate={controls}
              // component={MotionTable}
              className={classes.table}
              aria-label="Tandem Brand Services"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    component={MotionTableCell}
                    variants={cellVariants}
                    component="th"
                    scope="row"
                    colSpan={2}
                  >
                    <Typography variant="h4">Brand Services</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                    Brand Ideation
                  </TableCell>
                  <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                    Information Design
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                    Packaging & P.O.P
                  </TableCell>
                  <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                    Go-to-market strategies
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                    Logo Development
                  </TableCell>
                  <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                    Branded Environments
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                    Internal Communications
                  </TableCell>
                  <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                    B2B sales programs
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                    Print Ad Campaigns
                  </TableCell>
                  <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                    Brand Identities
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                    Naming Conventions
                  </TableCell>
                  <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                    Collateral & Promotions
                  </TableCell>
                </TableRow>
              </TableBody>
            </motion.table>
          </AnimatePresence>
        </TableContainer>
      </Hidden>
    </>
  );
}
