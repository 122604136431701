import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { InView } from "react-intersection-observer";
import { Grid, Typography, makeStyles, Container, ButtonBase, Grow } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import Tuscan from "./images/footer/Identities_TuscanRise@2x.jpg";
import Zoo from "./images/footer/Zoo_FlamingoAd@2x.jpg";
import Remington from "./images/footer/Remington_Wood1@2x.jpg";
import TwentyYears from "./images/footer/20Years_1@2x.jpg";
import Fresnel from "./images/footer/Fresnel_Web@2x.jpg";
import Mawer from "./images/footer/Mawer_Mag1@2x.jpg";

function Project(props) {
  const classes = useStyles();
  const handleProjectClick = props.handleProjectClick;
  const getTimeOut = props.getTimeOut;
  return (
    <Container className={classes.root}>
      <Grid spacing={6} container direction="row" justifyContent="space-evenly" alignItems="center">
        {projectCategories.map((category, index) => {
          return (
            <InView key={index}>
              {({ inView, ref, entry }) => {
                return (
                  <Grow ref={ref} in={inView} timeout={getTimeOut()}>
                    <Grid item xs={10} sm={4}>
                      <Grid container direction="column">
                        <Grid item>
                          <ButtonBase focusRipple key={category.image}>
                            <StyledImg
                              src={category.image}
                              data-path={category.path}
                              onClick={handleProjectClick}
                            />
                          </ButtonBase>
                        </Grid>
                        {(index + 1) % 2 === 0 && (
                          <WhiteGridItem item>
                            <Typography variant="h4">{category.title}</Typography>
                            <Typography variant="body1">{category.description}</Typography>
                            <Button data-path={category.path} onClick={handleProjectClick}>
                              See More
                            </Button>
                          </WhiteGridItem>
                        )}
                        {(index + 1) % 2 !== 0 && (
                          <BlackGridItem item>
                            <Typography variant="h4">{category.title}</Typography>
                            <Typography variant="body1">{category.description}</Typography>
                            <Button data-path={category.path} onClick={handleProjectClick}>
                              See More
                            </Button>
                          </BlackGridItem>
                        )}
                      </Grid>
                    </Grid>
                  </Grow>
                );
              }}
            </InView>
          );
        })}
      </Grid>
    </Container>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 142,
    marginBottom: "2em",
    [theme.breakpoints.up("sm")]: {
      marginTop: 114,
      marginBottom: "2em",
    },
  },
}));

const StyledImg = styled("img")`
  width: 100%;
  max-height: 314px;
  max-width: 534px;
`;

const BlackGridItem = styled(Grid)`
  background-color: ${props => props.theme.palette.common.black};
  padding: 2em 1em;
  height: 100%;

  > p {
    margin-bottom: 1em;
  }
  > h4,
  p {
    color: ${props => props.theme.palette.common.white};
  }
  > button {
    background-color: ${props => props.theme.palette.secondary.main};
    width: 114px;
    font-size: 12px;
    color: rgb(0, 0, 0, 1);
  }
  > button:hover {
    background-color: ${props => props.theme.palette.secondary.dark};
  }
`;

const WhiteGridItem = styled(Grid)`
  padding: 2em 2em;
  > p {
    margin-bottom: 1em;
  }
  > h4,
  p {
    color: ${props => props.theme.palette.common.black};
  }
  > button {
    background-color: ${props => props.theme.palette.secondary.main};
    width: 114px;
    font-size: 12px;
    color: ${props => props.theme.palette.common.black};
  }
  > button:hover {
    background-color: ${props => props.theme.palette.secondary.dark};
  }
`;

const projectCategories = [
  {
    title: "Identities",
    description:
      "Whether it’s a logo update, wordmarks, or a moniker, Tandem provides the services to solve your identity challenges.",
    image: Tuscan,
    path: "identities",
  },
  {
    title: "Advertising",
    description:
      "A great piece of creative can find a home on many avenues: posters, social media, online ads, and more.",
    image: Zoo,
    path: "advertising",
  },
  {
    title: "Environments",
    description:
      "Design centres, point of sale, murals, or even interactive experiences bring the brand to life in a tangible way.",
    image: Remington,
    path: "environments",
  },
  {
    title: "Brand Development",
    description:
      "A suite of tactics adept at the heavy lifting, from presentations to information design.",
    image: Mawer,
    path: "branding",
  },
  {
    title: "Experiences",
    description:
      "A great piece of creative can find a home on many avenues: posters, social media, online ads, and more.",
    image: TwentyYears,
    path: "experiences",
  },
  {
    title: "Digital",
    description:
      "Memorable design, a knack for SEO, & intelligent programming; benefits to your business with tailored messaging.",
    image: Fresnel,
    path: "digital",
  },
];
// TODO Add props
Project.propTypes = {};

export default Project;
