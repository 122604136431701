import React from "react";
import PropTypes from "prop-types";
import LazyLoad from "react-lazyload";
import { InView } from "react-intersection-observer";
import { Grid, Grow, makeStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import PlaceholderImage from "./images/identities/ArcadiusStationary.jpg";
import SimpleResponsivePicture from "./SimpleResponsivePicture";
function GrowLazyGrid(props) {
  const classes = useStyles();
  return (
    <InView>
      {({ inView, ref, entry }) => {
        return (
          <Grow ref={ref} in={inView} {...{ timeout: props.timeout }}>
            {/* <Grid item xs={props.gridWidth} style={props.style}> */}
            <div style={props.style}>
              <LazyLoad
                className={classes.root}
                offset={100}
                once
                unmountIfInvisible
                width={300}
                height={200}
                placeholder={<Skeleton animation="wave" variant="rect" width={300} height={200} />}
              >
                <SimpleResponsivePicture
                  imageObj={props?.src || props?.src?.url}
                  // sx={{ ...imageStyle }}
                  objectFit={"contain"}
                />
                {/* <img alt="" src={props.src} className={props.imgclass} /> */}
              </LazyLoad>
            </div>
            {/* </Grid> */}
          </Grow>
        );
      }}
    </InView>
  );
}
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.projectBG,
  },
}));
GrowLazyGrid.propTypes = {
  timeout: PropTypes.number,
  gridWidth: PropTypes.number,
  imgclass: PropTypes.string,
  src: PropTypes.string,
};
GrowLazyGrid.defaultProps = {
  timeout: 200,
  gridWidth: 12,
  imgclass: "",
  src: "/images/default.jpg",
};

export default GrowLazyGrid;
