import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { InView } from "react-intersection-observer";
import LazyLoad from "react-lazyload";
import { Helmet } from "react-helmet";
import { Grid, makeStyles, Grow, Slide } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
function VideoContainer({ video, ...props }) {
  return (
    <Wrapper>
      <Helmet>
        <script src={`https://fast.wistia.com/embed/medias/${video}.jsonp`} async></script>
        <script src="https://fast.wistia.com/assets/external/E-v1.js" async></script>
      </Helmet>
      <div
        className="wistia_responsive_padding"
        style={{ padding: "56.25% 0 0 0", position: "relative" }}
      >
        <div
          className="wistia_responsive_wrapper"
          style={{
            height: "100%",
            left: 0,
            position: "absolute",
            top: 0,
            width: "100%",
          }}
        >
          <InView>
            {({ inView, ref, entry }) => {
              return (
                <Grow ref={ref} in={inView} timeout={300}>
                  <div
                    className={`wistia_embed wistia_async_${video} videoFoam=true allowtransparency=true`}
                    style={{ height: "100%", position: "relative", width: "100%" }}
                  >
                    &nbsp;
                  </div>
                </Grow>
              );
            }}
          </InView>
        </div>
      </div>
    </Wrapper>
  );
}

function Video(props) {
  const classes = useStyles();

  const { matches, project, getTimeOut } = props;
  return (
    <Grid
      container
      justifyContent="center"
      alignContent="space-between"
      className={classes.projectContainer}
    >
      <VideoContainer video={project.video}></VideoContainer>
    </Grid>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    overflow: "hidden",

    [theme.breakpoints.down("md")]: {
      marginBottom: 8,
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "64px",
    },
  },
  fullWidth: {
    width: "100%",

    margin: "0 auto",

    [theme.breakpoints.up("md")]: {
      // margin: 0,
    },
    [theme.breakpoints.up("lg")]: {
      // margin: "4rem 4rem 1rem 4rem",
    },
  },
  projectContainer: {
    // "& :first-of-type": {
    //   paddingTop: 400,
    // },
    "& :last-of-type": {
      // borderBottom: "solid 5px red",
    },
  },
  projectName: {
    marginRight: "1em",
    marginBottom: "1em",
    marginTop: ".25em",

    [theme.breakpoints.up("sm")]: {},
  },
}));

const Wrapper = styled("div")`
  width: 100vw;
  /* max-width: 768px; */
  margin-top: 1rem;
  margin-bottom: 1rem;
`;
Video.propTypes = { matches: PropTypes.bool, project: PropTypes.object };

export default Video;
