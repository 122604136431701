import { motion, AnimatePresence } from "framer-motion";
import { Grid, Typography, Container, Hidden } from "@material-ui/core";
import remingtonSketch from "../images/home/remingtonSketch@2x.jpg";
import gccMagazine from "../images/home/gccMagazine@2x.jpg";
import chandosBook from "../images/home/chandosBook@2x.jpg";
import cmaAds from "../images/home/cmaAds@2x.jpg";
import ArcadiusWeb from "../images/home/ArcadiusWeb@2x.jpg";
import arcadiusBranding from "../images/home/arcadiusBranding@2x.jpg";
import remingtonWebsite from "../images/home/remingtonWebsite@2x.jpg";
import artCloseup from "../images/home/artCloseup@2x.jpg";
import StudioChair from "../images/home/StudioChair@2x.jpg";
import remingtonBarnArt from "../images/home/remingtonBarnArt@2x.jpg";
import Radio from "../images/home/Studio_Section1_Radio@2x.jpg";
import twentyYearsTwentyBeers from "../images/home/twenty-years-twenty-beers@2x.jpg";
import fresnelWebsite from "../images/home/fresnelWebsite@2x.jpg";
import gatestoneFolder from "../images/home/gatestone-folder.jpg";
import homeHero from "../images/home/home-hero.jpg";

const HomeData = {
  headerVariant: {
    initial: {
      backgroundSize: "120%",
      backgroundPositionX: "center",
      backgroundPositionY: "100%",
    },
    initialMobile: {
      backgroundSize: "200%",
      backgroundPositionX: "center",
      backgroundPositionY: "center",
    },
    animate: {
      backgroundSize: "100%",
      backgroundPositionY: "0%",
    },
    animateMobile: {
      backgroundSize: "250%",

      backgroundPositionY: ["0px", "0px", "0px", "0px"],
    },
    transition: { type: "linear", duration: 15, linear: 1 },
  },
  headerImage: homeHero,
  backgroundposition: "center/cover",
  heading: "Making it Matter",
  description: `Vinyl that spans six decades. Authentic barn board. Marconi stereo. Design musings.
    And a whole lot more.`,
  content: {
    one: [
      {
        img: twentyYearsTwentyBeers,
        title: "Twenty Years, Twenty Beers",
        cols: 1,
      },
      {
        img: gatestoneFolder,
        title: "Gatestone Folder",
        cols: 2,
      },
      {
        img: remingtonBarnArt,
        title: "Remington Art",
        cols: 1,
      },
      {
        img: fresnelWebsite,
        title: "Fresnel Website",
        cols: 1,
      },
      {
        img: StudioChair,
        title: "A chair in the Tandem Studio",
        cols: 1,
      },
    ],

    two: [
      {
        img: remingtonWebsite,
        title: "Remington Land Website",
        cols: 1,
      },
      {
        img: arcadiusBranding,
        title: "Arcadius Branding",
        cols: 1,
      },
      {
        img: artCloseup,
        title: "Remington art installation up close",
        cols: 1,
      },
      {
        img: remingtonSketch,
        title: "A sketch from a Remington project",
        cols: 2,
      },
      {
        img: chandosBook,
        title: "The Chandos Book",
        cols: 1,
      },
    ],
    misc: {
      first: {
        img: Radio,
        title: "The vintage console stereo",
      },
      second: {
        img: cmaAds,
        title: "Ads for the CMA",
      },
      third: {
        img: ArcadiusWeb,
        title: "Arcadius Website",
      },
      fourth: {
        img: gccMagazine,
        title: "Grains Connect magazine ad",
      },
    },
  },
};

export default HomeData;
