import React from "react";
import PropTypes from "prop-types";
import LazyLoad from "react-lazyload";
import styled from "styled-components";
import { useLocation, useHistory } from "react-router-dom";
import { InView } from "react-intersection-observer";

import Tuscan2x from "./images/footer/Identities_TuscanRise@2x.jpg";
import Zoo2x from "./images/footer/Zoo_FlamingoAd@2x.jpg";
import Remington2x from "./images/footer/Remington_Wood1@2x.jpg";
import TwentyYears2x from "./images/footer/20Years_1@2x.jpg";
import Fresnel2x from "./images/footer/Fresnel_Web@2x.jpg";
import Mawer2x from "./images/footer/Mawer_Mag1@2x.jpg";

import Tuscan from "./images/footer/Identities_TuscanRise.jpg";
import Zoo from "./images/footer/Zoo_FlamingoAd.jpg";
import Remington from "./images/footer/Remington_Wood1.jpg";
import TwentyYears from "./images/footer/20Years_1.jpg";
import Fresnel from "./images/footer/Fresnel_Web.jpg";
import Mawer from "./images/footer/Mawer_Mag1.jpg";

import { ButtonBase, Grid, Typography, Container, Hidden, Button, Grow } from "@material-ui/core";

function SeeOurWork(props) {
  const { matches, scrollToTop } = props;
  const match = useLocation();
  let history = useHistory();
  let projects = getRandom(
    projectCategories.filter(project => {
      const regex = /\/projects\//gi;
      const category = match.pathname.replace(regex, "");
      return project.path !== category;
    }),
    3
  );
  function getRandom(arr, n) {
    let result = new Array(n),
      len = arr.length,
      taken = new Array(len);
    if (n > len) throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
      let x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  }
  const handleOnClick = event => {
    history.push(
      `${match.pathname === "/services" ? "projects/" : ""}${event.currentTarget.dataset.path}`
    );
    scrollToTop();
  };
  // Need to adjust layout for multi lines
  const hasBranding = projects.some(item => item.title === "Brand Development");

  return (
    <SowContainer maxWidth="xl">
      <Grid
        spacing={3}
        container
        direction="row"
        justifyContent="space-around"
        alignItems="flex-start"
      >
        <Grid item xs={12}>
          <Typography align="center" xs={12} variant="h2">
            See Our Work
          </Typography>
        </Grid>

        {projects.map((project, index, array) => {
          const timeout = props.getTimeOut();
          return (
            <InView key={`${project.path}${index}`}>
              {({ inView, ref, entry }) => {
                return (
                  <Grow ref={ref} in={inView} timeout={timeout}>
                    <Grid item xs={10} sm={4}>
                      <Grid
                        container
                        justifyContent="center"
                        direction="column"
                        alignContent="center"
                      >
                        <Grid item>
                          <LazyLoad>
                            <ButtonBase
                              focusRipple

                              // focusVisibleClassName={classes.focusVisible}
                              // style={{
                              //   width: image.width,
                              // }}
                            >
                              <StyledImg
                                data-path={project.path}
                                onClick={handleOnClick}
                                src={matches ? project.image : project.image2x}
                              />
                            </ButtonBase>
                          </LazyLoad>
                        </Grid>
                        <BlackGridItem index={index} item hasbranding={hasBranding.toString()}>
                          <Grid container justifyContent="space-between" alignItems="flex-end">
                            <Grid item xs={6} sm={12}>
                              <Typography variant="h4">{project.title}</Typography>
                            </Grid>
                            <Hidden smDown>
                              <Typography variant="body1">{project.description}</Typography>
                            </Hidden>
                            <Grid item xs={4}>
                              <Button
                                onClick={() => {
                                  history.push(
                                    `${match.pathname === "/services" ? "projects/" : ""}${
                                      project.path
                                    }`
                                  );
                                  scrollToTop();
                                }}
                                variant="contained"
                                size="small"
                              >
                                See More
                              </Button>
                            </Grid>
                          </Grid>
                        </BlackGridItem>
                      </Grid>
                    </Grid>
                  </Grow>
                );
              }}
            </InView>
          );
        })}
      </Grid>
    </SowContainer>
  );
}

const StyledImg = styled("img")`
  width: 100%;
`;
const SowContainer = styled(Container)`
  margin-bottom: 4em;
  margin-top: 4em;
`;
const BlackGridItem = styled(Grid)`
  background-color: ${props =>
    (props.index + 1) % 2 === 0
      ? props.theme.palette.common.white
      : props.theme.palette.common.black};
  padding: 1em;
  max-width: 534px;
  ${props => props.theme.breakpoints.up("md")} {
    min-height: 251px;
    padding: 2em 4em;
    min-height: 100px;
    max-width: 534px;
  }
  ${props => props.theme.breakpoints.up("md")} {
    min-height: 250px;
  }
  > p {
    margin-bottom: 1em;
  }
  > div > p {
    ${props => props.theme.breakpoints.up("sm")} {
      min-height: 121px;
    }
  }
  > div > div > h4 {
    ${props => props.theme.breakpoints.up("sm")} {
      min-height: ${props => {
        return props.hasbranding === "true" ? "75px" : "10px";
      }};
    }
    ${props => props.theme.breakpoints.up("lg")} {
      min-height: initial;
    }
  }
  > div > div > h4,
  p,
  h5 {
    color: ${props =>
      (props.index + 1) % 2 === 0
        ? props.theme.palette.common.black
        : props.theme.palette.common.white};
  }

  > div > div > button {
    background-color: ${props => props.theme.palette.secondary.dark};
    width: 80px;
    font-size: 12px;
    color: ${props => props.theme.palette.common.black};
    ${props => props.theme.breakpoints.up("sm")} {
      margin-top: 2rem;
    }
  }
`;
const projectCategories = [
  {
    title: "Identities",
    description:
      "Whether it’s a logo update, wordmarks, or a moniker, Tandem provides the services to solve your identity challenges.",
    image: Tuscan,
    image2x: Tuscan2x,
    path: "identities",
  },
  {
    title: "Advertising",
    description:
      "A great piece of creative can find a home on many avenues: posters, social media, online ads, and more.",
    image: Zoo,
    image2x: Zoo2x,
    path: "advertising",
  },
  {
    title: "Environments",
    description:
      "Design centres, point of sale, murals, or even interactive experiences bring the brand to life in a tangible way.",
    image: Remington,
    image2x: Remington2x,
    path: "environments",
  },
  {
    title: "Brand Development",
    description:
      "A suite of tactics adept at the heavy lifting, from presentations to information design.",
    image: Mawer,
    image2x: Mawer2x,
    path: "branding",
  },
  {
    title: "Experiences",
    description:
      "A great piece of creative can find a home on many avenues: posters, social media, online ads, and more.",
    image: TwentyYears,
    image2x: TwentyYears2x,
    path: "experiences",
  },
  {
    title: "Digital",
    description:
      "Memorable design, a knack for SEO, & intelligent programming; benefits to your business with tailored messaging.",
    image: Fresnel,
    image2x: Fresnel2x,
    path: "digital",
  },
];
SeeOurWork.propTypes = { matches: PropTypes.bool, scrollToTop: PropTypes.func };
SeeOurWork.defaultProps = { matches: false };
export default SeeOurWork;
