import React, { useEffect } from "react";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import {
  Typography,
  makeStyles,
  Hidden,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  withStyles,
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 400,
    maxWidth: 1920,
    width: "100%",
    margin: "3em auto",
  },
}));

const TableCell = withStyles(theme => ({
  root: {
    borderBottomColor: theme.palette.secondary.dark,
    borderBottomWidth: 1,
    width: "50%",
    [theme.breakpoints.up("sm")]: {
      width: "25%",
    },
  },
}))(MuiTableCell);
const MotionTableCell = props => {
  return (
    <motion.td variants={props.variants} className={props.className}>
      {props.children}
    </motion.td>
  );
};
const MotionRow = props => {
  return (
    <motion.row variants={props.variants} className={props.className}>
      {props.children}
    </motion.row>
  );
};
const MotionTable = props => {
  return <motion.table {...props}>{props.children}</motion.table>;
};
const tableVariant = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 1, staggerChildren: 0.1 },
  },
  exit: { opacity: 0 },
};
const cellVariants = {
  hidden: { opacity: 0, borderBottomStyle: "none" },
  visible: { opacity: 1, borderBottomStyle: "solid", transition: { duration: 0.3 } },
  exit: { opacity: 0 },
};
const rowVariants = {
  hidden: {},
  visible: {
    transition: { duration: 0.5 },
  },
  exit: { opacity: 0 },
};
export default function DigitalServices() {
  const classes = useStyles();
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: false, rootMargin: "-100px 0px" });
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
    if (!inView) {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <>
      <Hidden xsDown>
        <TableContainer>
          <motion.table
            style={{ borderCollapse: "collapse" }}
            initial="hidden"
            variants={tableVariant}
            ref={ref}
            exot="exit"
            animate={controls}
            // component={MotionTable}
            className={classes.table}
            aria-label="Tandem Digital Services"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  component={MotionTableCell}
                  variants={cellVariants}
                  scope="row"
                  component="th"
                  colSpan={4}
                >
                  <Typography variant="h4">Digital Services</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                  Web Development
                </TableCell>
                <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                  Data & Analytics Reporting
                </TableCell>
                <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                  Content Management Systems
                </TableCell>
                <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                  Website Management
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                  Search Engine Optimization
                </TableCell>
                <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                  Media Management
                </TableCell>
                <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                  Online Advertising Campaigns
                </TableCell>
                <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                  Presentations
                </TableCell>
              </TableRow>
            </TableBody>
          </motion.table>
        </TableContainer>
      </Hidden>

      <Hidden smUp>
        <TableContainer component={Paper}>
          <motion.table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  component={MotionTableCell}
                  variants={cellVariants}
                  scope="row"
                  component="th"
                  colSpan={2}
                >
                  <Typography variant="h4">Digital Services</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                  Web Development
                </TableCell>
                <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                  Data & Analytics Reporting
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                  Content Management Systems
                </TableCell>
                <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                  Website Management
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                  Search Engine Optimization
                </TableCell>
                <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                  Media Management
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                  Online Advertising Campaigns
                </TableCell>
                <TableCell component={MotionTableCell} variants={cellVariants} scope="row">
                  Presentations
                </TableCell>
              </TableRow>
            </TableBody>
          </motion.table>
        </TableContainer>
      </Hidden>
    </>
  );
}
