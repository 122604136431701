import React, { useState, useEffect } from "react";
import { Switch, Route, useLocation, Redirect, useHistory } from "react-router-dom";
import { useTheme, Fab, useMediaQuery, Container, Button } from "@material-ui/core";
import { motion, AnimatePresence } from "framer-motion";

import { InView } from "react-intersection-observer";
import ArrowUpward from "@material-ui/icons/ArrowUpward";

// import ArrowDownward from "@material-ui/icons/ArrowDownward";
// import ScrollDown from "./components/ScrollDown";

import { PageView, initGA, Event } from "./components/Tracking";

import MainNavigation from "./components/MainNavigation";
import TitleHeader from "./components/TitleHeader";
import ServicesHeader from "./components/ServicesHeader";
import SubNavigation from "./components/SubNavigation";

import Home from "./components/Home";
import Services from "./components/Services";
import AnimatedCard from "./components/AnimatedCard";
import Project from "./components/Project";
import Footer from "./components/Footer";
import SeeOurWork from "./components/SeeOurWork";
import LocationMap from "./components/Location";
import ProjectHeader from "./components/ProjectHeader";
import ProjectsGeneric from "./components/ProjectsGeneric";
import InstagramBlock from "./components/InstagramBlock";
import ScrollTop from "./components/ScrollTop";
import SketchBook from "./components/SketchBook";
import SketchBookHeader from "./components/SketchBookHeader";
import ProjectLandingHeader from "./components/ProjectLandingHeader";
import ProjectsHeaderImage from "./components/images/header/ProjectsHeader.jpg";

//data for various projects sections
// import Identities from "./components/data/Identities";
// import Advertising from "./components/data/Advertising";
// import Experiences from "./components/data/Experiences";
// import Environments from "./components/data/Environments";
// import Digital from "./components/data/Digital";
import Branding from "./components/data/Branding";
import HomeData from "./components/data/HomeData";

import DynamicComponentLoader from "./components/DynamicComponent";
//cms data for projects sections
import {
  // getTestimonials,
  getIdentities,
  getAdvertising,
  getBrandDevelopment,
  getDigital,
  getEnvironment,
  getExperience,
  getPlayground,
} from "./data";
import "./App.css";

import ServicesHeaderImage from "./components/images/Services/tandem-studio-recordplayer.jpg";

const pageAnimations = {
  hidden: {
    opacity: 0.05,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.25,
    },
  },

  exit: { opacity: 0.0, transition: { duration: 0.25 } },
};

function App(props) {
  useEffect(() => {
    initGA("G-Y78N8CKVHY");
  }, []);
  const [identitiesData, setIdentitiesData] = useState([{}]);
  const [advertisingData, setAdvertisingData] = useState([{}]);
  const [brandDevelopmentData, setBrandDevelopmentData] = useState([{}]);
  const [digitalData, setDigitalData] = useState([{}]);
  const [environmentData, setEnvironmentData] = useState([{}]);
  const [experienceData, setExperienceData] = useState([{}]);
  const [playgroundData, setPlaygroundData] = useState([{}]);
  // useEffect(() => {
  //   PageView();
  // }, []);
  useEffect(() => {
    getIdentities(setIdentitiesData, "tandem-projectsid");
    getAdvertising(setAdvertisingData, "tandem-projectsad");
    getBrandDevelopment(setBrandDevelopmentData, "tandem-projectsbd");
    getEnvironment(setEnvironmentData, "tandem-projectsenv");
    getDigital(setDigitalData, "tandem-projectsdig");
    getExperience(setExperienceData, "tandem-projectsexp");
    getPlayground(setPlaygroundData, "tandem-projectspg");
  }, []);

  /* TODO Move the title and body used by the headers into the main content file */
  let history = useHistory();
  const theme = useTheme();
  //TODO use a better variable name for matches
  // matches is a boolean that indicates if it's a "small" screen
  const matches = useMediaQuery(theme.breakpoints.only("xs"));
  const locationObject = useLocation();
  const handleProjectClick = e => {
    history.push(`projects/${e.currentTarget.dataset.path}`);
    window.scrollTo({ top: 0, behavior: "auto" });
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
  };
  const getTimeOut = () => {
    const timeOutValues = [300, 400, 600, 800];
    return timeOutValues[Math.floor(Math.random() * timeOutValues.length)];
  };

  const ProjectComp = props => {
    return (
      <>
        <ProjectHeader
          content={{ ...props.content, ...props.content.Hero }}
          matches={props.matches}
          locationObj={props.locationObject}
        />

        <SubNavigation scrollToTop={scrollToTop} matches={matches} />

        <ProjectsGeneric
          getTimeOut={props.getTimeOut}
          content={props?.content?.projects}
          matches={props.matches}
        />

        <SeeOurWork scrollToTop={props.scrollToTop} getTimeOut={props.getTimeOut} />
      </>
    );
  };
  return (
    <>
      <Container className="App" maxWidth="xl">
        <MainNavigation pathname={locationObject.pathname} scrollToTop={scrollToTop} />
      </Container>
      <Switch>
        <Redirect from="/:url*(/+)" to={locationObject.pathname.slice(0, -1)} />
        <Route exact path="/sketchbook">
          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={locationObject.pathname}
              exit="exit"
              initial="hidden"
              animate="visible"
              variants={pageAnimations}
            >
              <SketchBookHeader headerImage={ServicesHeaderImage} />

              <SketchBook getTimeOut={getTimeOut} matches={matches}></SketchBook>
            </motion.div>
          </AnimatePresence>

          {/* <InstagramBlock /> */}
        </Route>
        <Route exact path="/studio">
          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={locationObject.pathname}
              exit="exit"
              initial="hidden"
              animate="visible"
              variants={pageAnimations}
            >
              <TitleHeader location={locationObject.pathname} />

              <Home HomeData={HomeData} matches={matches}></Home>
            </motion.div>
          </AnimatePresence>
          <InView>
            {({ inView, ref, entry }) => {
              return <LocationMap matches={matches} triggeronce={false} animateIcon={inView} />;
            }}
          </InView>
          {/* <InstagramBlock /> */}
        </Route>

        <Route exact path="/services">
          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={locationObject.pathname}
              exit="exit"
              initial="hidden"
              animate="visible"
              variants={pageAnimations}
            >
              <ServicesHeader headerImage={ServicesHeaderImage} />
              <Services matches={matches}></Services>
              <SeeOurWork getTimeOut={getTimeOut} scrollToTop={scrollToTop} />
            </motion.div>
          </AnimatePresence>
        </Route>

        <Route exact path="/hello/tf">
          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={locationObject.pathname}
              exit="exit"
              initial="hidden"
              animate="visible"
              variants={pageAnimations}
            >
              <AnimatedCard
                card="tf"
                handleProjectClick={handleProjectClick}
                scrollToTop={scrollToTop}
                getTimeOut={getTimeOut}
              />
            </motion.div>
          </AnimatePresence>
        </Route>

        <Route exact path="/hello/jp">
          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={locationObject.pathname}
              exit="exit"
              initial="hidden"
              animate="visible"
              variants={pageAnimations}
            >
              <AnimatedCard
                card="jp"
                handleProjectClick={handleProjectClick}
                scrollToTop={scrollToTop}
                getTimeOut={getTimeOut}
              />
            </motion.div>
          </AnimatePresence>
        </Route>
        <Route exact path="/hello/ej">
          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={locationObject.pathname}
              exit="exit"
              initial="hidden"
              animate="visible"
              variants={pageAnimations}
            >
              <AnimatedCard
                card="ej"
                handleProjectClick={handleProjectClick}
                scrollToTop={scrollToTop}
                getTimeOut={getTimeOut}
              />
            </motion.div>
          </AnimatePresence>
        </Route>
        <Route exact path="/dc">
          {/* {identitiesData?.Projects?.map((project, index) => {
            console.log(project);
            return (
              <DynamicComponentLoader
                key={project.ProjectName}
                getTimeOut={getTimeOut}
                matches={matches}
                project=
                component={project.LayoutTypes}
                props={project}
              />
            );
          })} */}
        </Route>
        <Route exact path="/hello/ak">
          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={locationObject.pathname}
              exit="exit"
              initial="hidden"
              animate="visible"
              variants={pageAnimations}
            >
              <AnimatedCard
                card="ak"
                handleProjectClick={handleProjectClick}
                scrollToTop={scrollToTop}
                getTimeOut={getTimeOut}
              />
            </motion.div>
          </AnimatePresence>
        </Route>
        <Route exact path="/hello/kh">
          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={locationObject.pathname}
              exit="exit"
              initial="hidden"
              animate="visible"
              variants={pageAnimations}
            >
              <AnimatedCard
                card="kh"
                handleProjectClick={handleProjectClick}
                scrollToTop={scrollToTop}
                getTimeOut={getTimeOut}
              />
            </motion.div>
          </AnimatePresence>
        </Route>
        <Route exact path="/projects">
          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={locationObject.pathname}
              exit="exit"
              initial="hidden"
              animate="visible"
              variants={pageAnimations}
            >
              <ProjectLandingHeader headerImage={ProjectsHeaderImage} />
              <Project
                handleProjectClick={handleProjectClick}
                scrollToTop={scrollToTop}
                getTimeOut={getTimeOut}
              />
            </motion.div>
          </AnimatePresence>
        </Route>

        {/* Move props into data file. e.g Identities.js */}
        <Route exact path="/projects/playground">
          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={locationObject.pathname}
              exit="exit"
              initial="hidden"
              animate="visible"
              variants={pageAnimations}
            >
              <ProjectComp
                pageanimations={pageAnimations}
                locationObject={locationObject}
                content={playgroundData}
                matches={matches}
                scrollToTop={scrollToTop}
                getTimeOut={getTimeOut}
              />
            </motion.div>
          </AnimatePresence>
        </Route>
        <Route exact path="/projects/identities">
          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={locationObject.pathname}
              exit="exit"
              initial="hidden"
              animate="visible"
              variants={pageAnimations}
            >
              <ProjectComp
                pageanimations={pageAnimations}
                locationObject={locationObject}
                content={identitiesData}
                matches={matches}
                scrollToTop={scrollToTop}
                getTimeOut={getTimeOut}
              />
            </motion.div>
          </AnimatePresence>
        </Route>

        <Route exact path="/projects/advertising">
          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={locationObject.pathname}
              exit="exit"
              initial="hidden"
              animate="visible"
              variants={pageAnimations}
            >
              <ProjectComp
                pageanimations={pageAnimations}
                locationObject={locationObject}
                content={advertisingData}
                matches={matches}
                scrollToTop={scrollToTop}
                getTimeOut={getTimeOut}
              />
            </motion.div>
          </AnimatePresence>
        </Route>

        <Route exact path="/projects/environments">
          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={locationObject.pathname}
              exit="exit"
              initial="hidden"
              animate="visible"
              variants={pageAnimations}
            >
              <ProjectComp
                pageanimations={pageAnimations}
                locationObject={locationObject}
                content={environmentData}
                matches={matches}
                scrollToTop={scrollToTop}
                getTimeOut={getTimeOut}
              />
            </motion.div>
          </AnimatePresence>
        </Route>

        <Route exact path="/projects/branding">
          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={locationObject.pathname}
              exit="exit"
              initial="hidden"
              animate="visible"
              variants={pageAnimations}
            >
              <ProjectComp
                pageanimations={pageAnimations}
                locationObject={locationObject}
                content={brandDevelopmentData}
                matches={matches}
                scrollToTop={scrollToTop}
                getTimeOut={getTimeOut}
              />
            </motion.div>
          </AnimatePresence>
        </Route>

        <Route exact path="/projects/experiences">
          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={locationObject.pathname}
              exit="exit"
              initial="hidden"
              animate="visible"
              variants={pageAnimations}
            >
              <ProjectComp
                pageanimations={pageAnimations}
                locationObject={locationObject}
                content={experienceData}
                matches={matches}
                scrollToTop={scrollToTop}
                getTimeOut={getTimeOut}
              />
            </motion.div>
          </AnimatePresence>
        </Route>

        <Route exact path="/projects/digital">
          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={locationObject.pathname}
              exit="exit"
              initial="hidden"
              animate="visible"
              variants={pageAnimations}
            >
              <ProjectComp
                pageanimations={pageAnimations}
                locationObject={locationObject}
                content={digitalData}
                matches={matches}
                scrollToTop={scrollToTop}
                getTimeOut={getTimeOut}
              />
            </motion.div>
          </AnimatePresence>
        </Route>
        <Route exact path="/">
          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={locationObject.pathname}
              exit="exit"
              initial="hidden"
              animate="visible"
              variants={pageAnimations}
            >
              <TitleHeader location={locationObject.pathname} />

              <Home HomeData={HomeData} matches={matches}></Home>
            </motion.div>
          </AnimatePresence>
          <InView>
            {({ inView, ref, entry }) => {
              return <LocationMap matches={matches} animateIcon={inView} />;
            }}
          </InView>
          <InstagramBlock />
        </Route>
        <Route>
          <div style={{ marginTop: 300 }}> Error 404 no match </div>
        </Route>
      </Switch>
      <Footer />
      <ScrollTop {...props}>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <ArrowUpward />
        </Fab>
      </ScrollTop>

      {/* <div style={{ width: "100%" }}>
        <ScrollDown window={window}>
          <Button
            variant="contained"
            size="small"
            style={{
              backgroundColor: "rgba(255,255,255,.75",
            }}
          >
            More
            <ArrowDownward style={{ stroke: "white", fontSize: "2em" }} aria-label="scroll down" />
          </Button>
        </ScrollDown>
      </div> */}
    </>
  );
}

export default App;
