import React, { useState } from "react";
import styled from "styled-components";
import { Box } from "@material-ui/core";
import PlayButton from "../images/PlayButton.png";
export default function AudioAsset({ play, ...props }) {
  const [showButton, setshowButton] = useState(true);

  const handleAudioClick = () => {
    play();
    setshowButton(false);
  };
  return (
    <Wrapper>
      <img
        src={PlayButton}
        onClick={handleAudioClick}
        style={{ display: showButton ? "flex" : "none" }}
        role="button"
        alt="Audio Play Button"
      />
    </Wrapper>
  );
}

const Wrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100px;
  color: red;
  z-index: 999;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  img {
    width: 10%;
    max-width: 200px;
    height: auto;
  }
`;
