import React from "react";
import PropTypes from "prop-types";

import { Grid, makeStyles, Container, Hidden } from "@material-ui/core";
import GrowLazyGrid from "../GrowLazyGrid";

function OneTwo(props) {
  const classes = useStyles();

  const { matches, project, getTimeOut } = props;

  return (
    <div className={classes.projectContainer}>
      <GrowLazyGrid
        src={project?.ProjectAssets?.data[0]?.attributes}
        gridWidth={6}
        imgclass={classes.fullWidth}
        getTimeOut={getTimeOut()}
        style={{ gridArea: `a` }}
      />

      <GrowLazyGrid
        src={project?.ProjectAssets?.data[1]?.attributes}
        gridWidth={12}
        imgclass={classes.fullWidth}
        getTimeOut={getTimeOut()}
        style={{ gridArea: "b" }}
      />

      <GrowLazyGrid
        src={project?.ProjectAssets?.data[2]?.attributes}
        gridWidth={12}
        imgclass={classes.fullWidth}
        getTimeOut={getTimeOut()}
        style={{ gridArea: "c" }}
      />
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    overflow: "hidden",

    [theme.breakpoints.down("md")]: {
      marginBottom: 8,
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "64px",
    },
  },
  fullWidth: {
    width: "100%",

    margin: "0 auto",

    [theme.breakpoints.up("md")]: {
      // margin: 0,
    },
    [theme.breakpoints.up("lg")]: {
      // margin: "4rem 4rem 1rem 4rem",
    },
  },
  projectContainer: {
    overflow: "hidden",
    display: "grid",
    gridTemplateAreas: `"a b" "a c"`,
    gap: ".8% .8%",
  },
}));

OneTwo.propTypes = { matches: PropTypes.bool, project: PropTypes.object };
OneTwo.defaultProps = {
  matches: false,
  project: {
    data: [
      {
        id: 1,
        attributes: {
          name: "default.jpg",
          alternativeText: "placeholder no-image",
          caption: null,

          formats: {
            small: {
              url: "/images/default.jpg",
            },
          },

          ext: ".jpg",
          mime: "image/jpg",

          url: "/images/default.jpg",
        },
      },
    ],

    layout: "OneTwo",
    title: "Default Title",
  },
};
export default OneTwo;
