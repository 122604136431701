import React from "react";
import PropTypes from "prop-types";
import LazyLoad from "react-lazyload";
import { InView } from "react-intersection-observer";

import { Grid, Typography, makeStyles, useTheme, Container, Grow } from "@material-ui/core";

import ServicesData from "./data/ServicesData";
import BrandServices from "./BrandServices";
import DigitalServices from "./DigitalServices";

function Services(props) {
  const { matches } = props;
  const classes = useStyles();
  return (
    <Container maxWidth="xl" disableGutters={matches}>
      <Grid
        className={classes.description}
        container
        alignItems="flex-start"
        justifyContent="space-evenly"
      >
        <Grid className={classes.descriptionBlock} item xs={12} sm={3}>
          <Typography variant="h4">
            Who <span className={classes.orangeText}>you</span> are
          </Typography>
          <Typography variant="body1">
            Clients approach Tandem with 3 challenges: You want to develop a unique brand that
            represents your product, services, people and history. You want to uncover the most
            effective strategies for implementing that brand. You want to bring your brand to life.
          </Typography>
        </Grid>
        <Grid className={classes.descriptionBlock} item xs={12} sm={3}>
          <Typography variant="h4">
            Who <span className={classes.greenText}>we</span> are
          </Typography>
          <Typography variant="body1">
            We’re masters of advertising, design, digital, and brand strategy. For over 25 years,
            Tandem has employed a range of tools, insight and creativity to help clients execute
            marketing challenges, and more importantly, we customize our approach to your unique
            starting point.
          </Typography>
        </Grid>
      </Grid>
      <Grid
        spacing={2}
        container
        alignItems="flex-start"
        justifyContent="space-evenly"
        className={classes.imageGroup}
      >
        {ServicesData.map((tile, index) => {
          let timeout = 300 * index;
          timeout += 500;
          return (
            <Grid item xs={12} sm={6} key={index}>
              <LazyLoad>
                <InView threshold=".5">
                  {({ inView, ref, entry }) => {
                    return (
                      <Grow ref={ref} in={inView} {...{ timeout: timeout }}>
                        <img src={tile.image2x} alt={tile.title} width="100%" />
                      </Grow>
                    );
                  }}
                </InView>
              </LazyLoad>
            </Grid>
          );
        })}
      </Grid>
      <BrandServices />
      <DigitalServices />
    </Container>
  );
}

const useStyles = makeStyles(theme => ({
  gridList: {
    width: 1640,
    height: "auto",
  },
  textRight: {
    marginBottom: 100,
    [theme.breakpoints.down("md")]: {
      marginBottom: 8,
    },
  },
  fullWidth: {
    width: "100%",
    height: "100%",
    maxWidth: 1400,
    marginTop: "2em",
    marginBottom: "2em",
    [theme.breakpoints.up("md")]: {
      margin: "2em",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "4em 4em 1em 4em",
    },
  },
  description: {
    backgroundColor: theme.palette.grey[100],
    // padding: "2em",
    lineHeight: "2em",
  },
  descriptionBlock: {
    margin: 50,
  },
  greenText: {
    color: theme.palette.secondary.main,
  },
  orangeText: {
    color: theme.palette.secondary.highlight,
  },
  tmImage: {
    maxWidth: "100%",
    height: "calc(27vw)",
    maxHeight: 520,
    [theme.breakpoints.up("sm")]: {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  imageGroup: {
    marginTop: "3em",
    marginBottom: "3em",
  },
}));

Services.propTypes = { matches: PropTypes.bool };

export default Services;
