import React from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { Grid, Typography, Container, Hidden, rgbToHex } from "@material-ui/core";

import homeHero from "./images/home/home-hero.jpg";
const pageAnimations = {
  hidden: { x: -1000 },
  visible: { x: 0 },
  transition: {
    // when: "beforeChildren",
    delayChildren: 0.75,
    staggerChildren: 0.75,
    staggerDirection: 1,
  },
  exit: { x: -1000 },
};
const matterVariant = {
  hidden: {
    opacity: 0,
    scale: 0,
    // originX: 1
  },
  visible: {
    opacity: 1,
    scale: [4, 0.5, 1],
    transition: {
      delay: 0.75,
      duration: 0.5,
    },
    // originX: 0.5,
  },

  exit: { x: -1000 },
};

const bodyVariant = {
  hidden: { opacity: 0, y: -50 },
  visible: {
    opacity: 0.95,
    y: 0,
    transition: {
      delay: 1.25,
      type: "spring",
      stiffness: 50,
      duration: 0.25,
    },
  },
};

const IndividualVariants = {
  hidden: { color: "rgba(255,255,255,1)", opacity: 0, x: -400 },
  visible: {
    color: "rgba(255,255,255,1)",
    opacity: [0, 0.25, 0, 0.1, 0, 0.5, 0.35, 0.95],
    x: 0,
    transition: {
      x: { type: "spring", duration: 0.27 },
      opacity: { duration: 0.5 },
    },
  },
};
const variants = {
  hidden: { opacity: 0, x: -50 },
  visible: {
    zIndex: 2,
    opacity: 1,
    x: 0,

    transition: {
      type: "linear",

      duration: 0.5,
      when: "beforeChildren",

      staggerChildren: 0.05,
      staggerDirection: 1,
    },
  },
};
function TitleHeader(props) {
  const { location } = props;
  return (
    <Hero container direction="column" justifyContent="center">
      <Container maxWidth="xl">
        <AnimatePresence exitBeforeEnter>
          <motion.div
            initial="hidden"
            animate="visible"
            key={location}
            exit={{ x: -1000, transition: { duration: 0.5 } }}
            variants={pageAnimations}
          >
            <AnimatePresence exitBeforeEnter>
              <motion.div
                key="titleHeaderHeading"
                variants={variants}
                exit={{
                  scale: 0,
                  transition: {
                    duration: 0.5,
                  },
                }}
              >
                <Typography variant="h1">
                  {`Making`.split("").map((letter, index) => {
                    //break at the space since the letters are wrapped in span tags

                    return (
                      <motion.span
                        id={`letter${index}`}
                        exit={{ opacity: 0 }}
                        key={`${letter}${index}`}
                        variants={IndividualVariants}
                        style={{ display: "inline-block" }}
                      >
                        {letter}
                      </motion.span>
                    );
                  })}{" "}
                  {`it`.split("").map((letter, index) => {
                    //break at the space since the letters are wrapped in span tags

                    return (
                      <motion.span
                        id={`letter${index}`}
                        exit={{ opacity: 0 }}
                        key={`${letter}${index}`}
                        variants={IndividualVariants}
                        style={{ display: "inline-block" }}
                      >
                        {letter}
                      </motion.span>
                    );
                  })}{" "}
                  <motion.span
                    style={{ display: "inline-block" }}
                    exit={{ opacity: 0 }}
                    variants={matterVariant}
                  >
                    matter.
                  </motion.span>
                </Typography>
              </motion.div>
              <motion.div key={"body"} exit="exit" variants={bodyVariant}>
                <Typography variant="body1">
                  Vinyl that spans six decades. Authentic barn board. Marconi stereo. Design
                  musings. And a whole lot more.
                </Typography>
              </motion.div>
            </AnimatePresence>
          </motion.div>
        </AnimatePresence>
      </Container>
    </Hero>
  );
}
const MotionDiv = props => {
  const { initial, animate, transition } = props;
  return (
    <motion.div
      key="TitleHeader"
      {...props}
      initial={initial}
      animate={animate}
      transition={transition}
      transition={props.transitions}
    ></motion.div>
  );
};
const Hero = styled(Grid)`
  background: url(${homeHero}) right/cover no-repeat;
  margin-top: 93px;
  height: 600px;
  color: #ffffff;
  ${props => props.theme.breakpoints.up("sm")} {
    background: url(${homeHero}) center/cover no-repeat;
    max-height: 979px;
    height: 51vw;
    margin-top: 64px;
  }

  h1 {
    margin: 0 0 0 1rem;
    text-align: left;
    max-width: 1200px;
    ${props => props.theme.breakpoints.up("sm")} {
      margin: 0 0 0 5%;
    }
    span {
      color: ${props => props.theme.palette.secondary.dark};
    }
  }

  p {
    margin: 0 0 0 1rem;

    max-width: 290px;

    ${props => props.theme.breakpoints.up("sm")} {
      text-align: left;
      max-width: 1200px;
      margin: 0 1rem 0 5%;
    }
  }
`;

export default TitleHeader;
