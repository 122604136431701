import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { InView } from "react-intersection-observer";
import LazyLoad from "react-lazyload";
import AudioAsset from "./AudioAsset";
import { Grid, makeStyles, Grow, Slide } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import SimpleResponsivePicture from "../SimpleResponsivePicture";
import AudioPlayerWithProgressBar from "../AudioPlayerWithProgressBar";
function One(props) {
  const classes = useStyles();

  const { matches, project, getTimeOut } = props;

  const [isAudioAsset, setisAudioAsset] = useState(project.hasOwnProperty("AudioAsset"));

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="space-between"
      className={classes.projectContainer}
    >
      <InView>
        {({ inView, ref, entry }) => {
          return (
            <Grow ref={ref} in={inView} timeout={1000}>
              <Grid item container style={{ position: "relative" }}>
                <LazyLoad
                  height={400}
                  offset={100}
                  placeholder={
                    <Skeleton animation="wave" variant="rect" width={"100%"} height={"75vw"} />
                  }
                >
                  {(project?.ProjectAssets?.data[0]?.attributes || project?.ProjectAssets?.url) && (
                    <SimpleResponsivePicture
                      imageObj={
                        project?.ProjectAssets?.data[0]?.attributes || project?.ProjectAssets?.url
                      }
                      // sx={{ ...imageStyle }}
                      objectFit={"contain"}
                      className={classes.fullWidth}
                    />
                  )}
                </LazyLoad>
              </Grid>
            </Grow>
          );
        }}
      </InView>
    </Grid>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    overflow: "hidden",

    [theme.breakpoints.down("md")]: {
      marginBottom: 8,
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "64px",
    },
  },
  fullWidth: {
    width: "100%",

    margin: "0 auto",

    [theme.breakpoints.up("md")]: {
      // margin: 0,
    },
    [theme.breakpoints.up("lg")]: {
      // margin: "4rem 4rem 1rem 4rem",
    },
  },
  projectContainer: {
    // "& :first-of-type": {
    //   paddingTop: 400,
    // },
    "& :last-of-type": {
      // borderBottom: "solid 5px red",
    },
  },
  projectName: {
    marginRight: "1em",
    marginBottom: "1em",
    marginTop: ".25em",

    [theme.breakpoints.up("sm")]: {},
  },
}));

One.propTypes = { matches: PropTypes.bool, project: PropTypes.object };

export default One;
