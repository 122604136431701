import React, { useState } from "react";
import PropTypes from "prop-types";
import { NavLink as RouterNavLink } from "react-router-dom";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {
  Button,
  AppBar,
  Toolbar,
  makeStyles,
  Grid,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";

function SubNavigation(props) {
  const { scrollToTop } = props;
  const [showMenu, setShowMenu] = useState(false);

  const theme = useTheme();
  const classes = useStyles();
  // const { matches } = props;
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const navItemArray = [
    ["/projects/identities", "Identities"],
    ["/projects/advertising", "Advertising"],
    ["/projects/environments", "Environments"],
    ["/projects/branding", "Brand Development"],
    ["/projects/experiences", "Experiences"],
    ["/projects/digital", "Digital"],
  ];

  const handleShowMenuClick = () => {
    setShowMenu(!showMenu);
  };

  const handleMobileProjectsClick = () => {
    setShowMenu(false);
    scrollToTop();
  };
  return (
    <AppBar color="transparent" className={classes.root} id="stickytest">
      {!matches && (
        <Toolbar className={classes.toolbar}>
          <Grid
            container
            direction="row"
            justifyContent={matches ? "center" : "space-between"}
            alignItems="center"
            className={classes.subnav}
          >
            {navItemArray.map((item, index) => {
              return (
                <Button
                  component={RouterNavLink}
                  className={classes.navItem}
                  activeClassName={classes.selected}
                  to={item[0]}
                  onClick={props.scrollToTop}
                  key={index}
                >
                  {item[1]}
                </Button>
              );
            })}
          </Grid>
        </Toolbar>
      )}

      {matches && (
        <Toolbar className={classes.toolbar}>
          <Grid
            container
            direction="column"
            justifyContent={matches ? "center" : "space-between"}
            alignItems="center"
            className={classes.subnav}
          >
            <Button
              className={classes.navItem}
              onClick={handleShowMenuClick}
              endIcon={<KeyboardArrowDownIcon />}
            >
              See More Work
            </Button>

            {showMenu &&
              navItemArray.map((item, index) => {
                return (
                  <Button
                    component={RouterNavLink}
                    className={classes.navItem}
                    activeClassName={classes.selected}
                    to={item[0]}
                    onClick={handleMobileProjectsClick}
                    key={index}
                  >
                    {item[1]}
                  </Button>
                );
              })}
          </Grid>
        </Toolbar>
      )}
    </AppBar>
  );
}
const useStyles = makeStyles(theme => {
  return {
    root: {
      backgroundColor: theme.palette.secondary.main,
      //http://thenewcode.com/1052/position-sticky-scroll-to-top-then-fixed-in-pure-CSS
      position: "sticky",
      top: "92px",
      [theme.breakpoints.up("sm")]: {
        top: "64px",
      },
    },

    navigation: {
      marginLeft: "auto",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "0",
      },
    },
    navItem: {
      margin: "0",
      fontSize: 16,
    },
    logo: {
      width: "156px",
    },
    selected: {
      fontWeight: theme.typography.fontWeightBold,
    },
    toolbar: {
      margin: "0 50px 0",

      [theme.breakpoints.up("sm")]: {
        margin: "0 140px 0",
      },
    },
    subnav: {
      maxWidth: 1400,
      margin: "0 auto",
    },
  };
});

SubNavigation.propTypes = { scrollToTop: PropTypes.func };
export default SubNavigation;
