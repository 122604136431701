import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { InstagramMedia } from "react-instagram-media";
import Skeleton from "@material-ui/lab/Skeleton";
// import Feed from "react-instagram-authless-feed";
import { Grid, Typography, Container } from "@material-ui/core";
import HeartOverlay from "./images/heartOverlay.png";

const instagramPosts = [
  "https://www.instagram.com/p/CEchH2HH-xy",
  "https://www.instagram.com/p/CCEHmhOp4qA/",
  "https://www.instagram.com/p/CB3by3LJ2bu/",
  "https://www.instagram.com/p/B82Hm-Ahru0/",
];
// 941155389603907|fbc3f0058d62653db73e59529ca0e86f
// App Id 941155389603907
// fbc3f0058d62653db73e59529ca0e86f

// https://graph.facebook.com/oauth/access_token?client_id=941155389603907&client_secret=c65947cec6545cb2a6ae1ba28ce50424&grant_type=client_credentials

// the following may or may not work in working code
// {"access_token":${process.env.REACT_APP_IG_APP_TOKEN},"token_type":"bearer"}

// const instagramPosts = [];

function InstagramBlock() {
  const [html, setHtml] = useState([]);
  const [thumbnail, setThumbnail] = useState([]);
  useEffect(() => {
    setThumbnail([]);
    instagramPosts.forEach((post, index) => {
      axios
        .get(
          `https://graph.facebook.com/v9.0/instagram_oembed?url=${post}&access_token=${process.env.REACT_APP_IG_APP_TOKEN}`
        )
        .then(result => {
          // console.log(result);

          const regex = /https:\/\/www.instagram.com\/p\/[0-9a-zA-Z-\/]+/m;
          let m;
          m = regex.exec(result.data.html);
          // console.log(m);
          // if ((m = regex.exec(result.data.html)) !== null) {
          //   // The result can be accessed through the `m`-variable.
          //   m.forEach((match, groupIndex) => {
          //     console.log(`Found match, group ${groupIndex}: ${match}`);
          //   });
          // }
          setThumbnail(thumbnail => [
            ...thumbnail,
            {
              image: result.data.thumbnail_url,
              link: "https://www.instagram.com/tandem.creative/",
            },
          ]);
          // setHtml((html) => [...html, m[0]]);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    });
  }, []);

  return (
    <InstagramContainer maxWidth="xl">
      <Grid container direction="row" justifyContent="space-around" data-id="1">
        <Grid item xs={12}>
          <Title variant="h2" align="center">
            Follow us on Instagram
          </Title>
        </Grid>
        {/* <Feed userName="tandem.creative" className="Feed" classNameLoading="Loading" limit="4" /> */}
        {/* {thumbnail.map((thumb, index) => {
          <img key={index} src={thumbnail} />;
        })} */}
        {}
        {thumbnail.length > 0 &&
          thumbnail.map((post, index) => {
            return (
              <InstagramPost
                // className="container"
                item
                xs={5}
                sm={2}
                key={index}
                display_url={post.image}
              >
                <div className="container">
                  <img src={post.image} alt="Post Image" className="image" />
                  <div className="overlay">
                    <a href={post.link} target="tandem">
                      <img src={HeartOverlay} alt="Post Image" className="image" />
                    </a>
                  </div>
                </div>
                {/* <img src={post} /> */}
              </InstagramPost>
            );
          })}
      </Grid>
    </InstagramContainer>
  );
}

const InstagramContainer = styled(Container)`
  background-color: #f4f4f4;
  padding: 0 4em 4em;
`;

const InstagramPost = styled(Grid)`
  // background-image: ${props => `url(${props.display_url})`};
  // background-size: 140%;
  // background-position: center;
  // background-repeat: no-repeat;
  // height: 27vw;
  // margin-top: 2em;
  // ${props => props.theme.breakpoints.up("sm")} {
  //   width: calc(100vw / 4);
  // }
  // &:hover {
  //   box-shadow: inset 0 0 0 200px rgba(255, 255, 255, 0.8);
  // }
`;

const Title = styled(Typography)`
  margin-top: 3rem;
  margin-bottom: 3rem;
`;

export default InstagramBlock;
