import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  Grid,
  Typography,
  makeStyles,
  useTheme,
  Container,
  useMediaQuery,
} from "@material-ui/core";

function ServicesHeader(props) {
  let addBreak = null;
  addBreak = useMediaQuery("(max-width:1180px)");

  const { headerImage = "" } = props;

  const classes = useStyles();
  const variants = {
    hidden: {
      opacity: 0,
      y: -100,
      scale: 3,
    },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        ease: [0.6, 0.01, -0.05, 0.9],
        delayChildren: 0.25,
        staggerChildren: 0.15,
        staggerDirection: 1,
      },
    },
  };

  return (
    <Hero
      container
      alignItems="center"
      alignContent="center"
      justifyContent="flex-start"
      headerimage={headerImage}
    >
      <Container maxWidth="lg">
        <Typography variant="h1">
          <AnimatePresence exitBeforeEnter>
            <motion.div variants={variants} initial="hidden" animate="visible" className="details">
              <motion.span
                exit={{ opacity: 0 }}
                key={"What"}
                variants={variants}
                style={{ display: "inline-block" }}
              >
                Send
              </motion.span>{" "}
              <motion.span
                exit={{ opacity: 0 }}
                key={"we"}
                variants={variants}
                style={{ display: "inline-block" }}
                className={classes.greenText}
              >
                us
              </motion.span>{" "}
              {addBreak && <div></div>}
              <motion.span
                exit={{ opacity: 0 }}
                key={"can"}
                variants={variants}
                style={{ display: "inline-block", color: "white" }}
                className={classes.greenText}
              >
                your
              </motion.span>{" "}
              <motion.span
                exit={{ opacity: 0 }}
                key={"do"}
                variants={variants}
                style={{ display: "inline-block", color: "white" }}
                className={classes.greenText}
              >
                sketches
              </motion.span>{" "}
              {/* <motion.span
                exit={{ opacity: 0 }}
                key={"for"}
                variants={variants}
                style={{ display: "inline-block", color: "white" }}
                className={classes.greenText}
              >
                for
              </motion.span>{" "}
              <motion.span
                exit={{ opacity: 0 }}
                key={"you"}
                variants={variants}
                style={{ display: "inline-block" }}
                className={classes.orangeText}
              >
                you
              </motion.span> */}
            </motion.div>
          </AnimatePresence>
        </Typography>
      </Container>
    </Hero>
  );
}
const useStyles = makeStyles(theme => ({
  gridList: {
    width: 1640,
    height: "auto",
  },
  textRight: {
    marginBottom: 100,
    [theme.breakpoints.down("md")]: {
      marginBottom: 8,
    },
  },
  fullWidth: {
    width: "100%",
    height: "100%",
    maxWidth: 1400,
    marginTop: "2em",
    marginBottom: "2em",
    [theme.breakpoints.up("md")]: {
      margin: "2em",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "4em 4em 1em 4em",
    },
  },
  description: {
    backgroundColor: theme.palette.grey[100],
    // padding: "2em",
    lineHeight: "2em",
  },
  descriptionBlock: {
    margin: 50,
  },
  greenText: {
    color: theme.palette.secondary.main,
  },
  orangeText: {
    color: theme.palette.secondary.highlight,
  },
}));

const Hero = styled(Grid)`
  margin-top: 93px;
  background: url(${props => props.headerimage}) center/cover no-repeat;
  background-origin: border-box;
  background-color: green;
  box-shadow: inset 0 0 0 500px rgba(0, 0, 0, 0.75);
  max-height: 445px;
  height: 90.5vw;

  ${props => props.theme.breakpoints.up("sm")} {
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 445px;
    height: 51vw;
  }

  h1 {
    margin: 0 0 0 1rem;
    color: #ffffff;
    text-align: left;
    ${props => props.theme.breakpoints.up("sm")} {
      margin: 0 2rem 0 2rem;
    }
  }

  p {
    /* margin: 0 0 0 10rem; */
    margin: 0 1rem 0 1rem;
    color: #ffffff;
    text-align: left;
    white-space: pre-wrap;
    ${props => props.theme.breakpoints.up("sm")} {
      max-width: 1200px;
      margin: 0 2rem 0 2rem;
    }
  }
`;

ServicesHeader.propTypes = {
  body: PropTypes.string,
  title: PropTypes.string,
  headerImage: PropTypes.string,
  matches: PropTypes.bool,
};
ServicesHeader.defaultProps = {
  body: "The content for this section wasn't loaded correctly. Check ServicesHeader.js",
  title: "The title for this section wasn't loaded correctly. Check ServicesHeader.js",
  headerImage: "/images/default.jpg",
  matches: false,
};
export default ServicesHeader;
