import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { NavLink as RouterNavLink } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Container,
  AppBar,
  Toolbar,
  makeStyles,
  Grid,
  useTheme,
} from "@material-ui/core";
import { motion, useViewportScroll } from "framer-motion";
import TandemLogo from "./images/tandem-logo.svg";

function MainNavigation(props) {
  const { scrollToTop } = props;
  const classes = useStyles();
  const [progress, setProgress] = useState(0);
  const { scrollYProgress } = useViewportScroll();
  const theme = useTheme();

  useEffect(() => {
    scrollYProgress.onChange(v => {
      setProgress(v * 100);
    });
  }, [scrollYProgress]);

  return (
    <AppBar color="transparent" style={{ background: "white" }} position="fixed">
      <div
        // className="progress-icon"

        style={{
          backgroundColor: theme.palette.common.white,
        }}
      >
        <motion.div
          id="motionPath"
          style={{
            position: "relative",

            // right: progress2 + "%",
            backgroundColor: theme.palette.secondary.light,
            opacity: progress + 20 + "%",
            height: 2,
            borderRight: `5px solid ${theme.palette.secondary.dark}`,
            zIndex: 999,
            width: progress + "%",
            // pathLength: "500px",
            // Reverse direction of line animation
          }}
        />
      </div>
      <Toolbar className={classes.toolbar}>
        <Container maxWidth="xl">
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={12} sm={2}>
              <Button
                className={classes.logoContainer}
                component={RouterNavLink}
                to="/studio"
                onClick={scrollToTop}
              >
                <img alt="Tandem Marketing Calgary" className={classes.logo} src={TandemLogo} />
              </Button>
            </Grid>
            <Grid item xs={12} sm={5}>
              <ButtonGroup
                fullWidth={true}
                variant="text"
                color="default"
                aria-label="text primary button group"
              >
                <Button
                  component={RouterNavLink}
                  className={classes.navItem}
                  activeClassName={classes.selected}
                  style={{ borderRight: "none" }}
                  to="/studio"
                  variant="text"
                  onClick={scrollToTop}
                >
                  Studio
                </Button>
                <Button
                  component={RouterNavLink}
                  className={classes.navItem}
                  activeClassName={classes.selected}
                  style={{ borderRight: "none" }}
                  to="/services"
                  onClick={scrollToTop}
                >
                  Services
                </Button>
                <Button
                  component={RouterNavLink}
                  className={classes.navItem}
                  activeClassName={classes.selected}
                  to="/projects"
                  onClick={scrollToTop}
                >
                  Projects
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Container>{" "}
      </Toolbar>
    </AppBar>
  );
}
const useStyles = makeStyles(theme => {
  return {
    navigation: {
      marginLeft: "auto",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "0",
      },
    },
    navItem: {
      margin: "0",
      fontWeight: theme.typography.fontWeightBold,
    },
    logo: {
      width: "156px",
    },
    logoContainer: {
      margin: "0 auto",
      width: "100%",
    },
    selected: {
      color: theme.palette.secondary.dark,
    },
    toolbar: {
      margin: 0,
      padding: 0,
      maxWidth: "100vw",
      [theme.breakpoints.up("lg")]: {
        margin: "0 100px 0",
      },
    },
  };
});
MainNavigation.propTypes = { scrollToTop: PropTypes.func };

export default MainNavigation;
