import CryptoJS from "crypto-js";

export function getLocalStorage(setter, key) {
  const isLocalCorporateSet = localStorage.getItem(key);

  if (isLocalCorporateSet === null) {
    setter({});
  }

  setter(JSON.parse(localStorage.getItem(key)));
  return true;
}

export function getLocalStorageEncrypted(setter, key, phrase) {
  let returnValue = false;
  const isLocalCorporateSet = localStorage.getItem(key);

  if (isLocalCorporateSet === null) {
    setter({});
  }

  if (isLocalCorporateSet !== null) {
    var decryptedBytes = CryptoJS.AES.decrypt(isLocalCorporateSet, phrase);
    var plaintext = decryptedBytes.toString(CryptoJS.enc.Utf8);
    // setter(atob(JSON.parse(isLocalCorporateSet)));
    setter(JSON.parse(plaintext));
    returnValue = true;
  }

  return returnValue;
}
